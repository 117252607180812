<template>
  <div :style="{ width: width + 'px'}" style="margin: auto">
    <div style="position: relative">
      <div class="video-overlay" :style="{ height: height + 'px' }">
        <div class="video-overlay-buttons" :style="{ width: width + 'px'}">
          <Button v-if="cameras.length >= 1"
                  icon="pi pi-camera"
                  class="p-button-rounded p-mr-2"
                  v-tooltip.bottom="'Take Photo'"
                  @click="onTakePhoto()"/>
          <Button icon="pi pi-upload"
                  class="p-button-rounded"
                  v-tooltip.bottom="'Upload Photo'"
                  @click="onUploadPhoto()"/>
        </div>
      </div>
    </div>
    <div>
      <img v-if="hasPhotoSet()" :src="modelValue" :width="width" :height="height" alt="photo"/>
      <div v-else>
        <video ref="video" :src="modelValue" :width="width" :height="height" autoplay="true"/>
      </div>
    </div>
    <div v-if="cameras.length > 1 && !hasPhotoSet()">
      <Dropdown v-model="source" :options="availableCameras" optionLabel="name" optionValue="id"/>
    </div>
    <input type="file" ref="imageUpload" accept="image/*" style="display:none" @change="onPhotoSelected()"/>
  </div>
</template>

<script>
export default {
  name: 'MemberPhoto',
  props: {
    modelValue: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 180
    },
    height: {
      type: Number,
      default: 220
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      cameras: [],          // the cameras that are returned by the enumerate devices function
      availableCameras: [], // a list built off cameras, for easier consumption by the dropdown
      source: null,         // the current video source
      canvas: null,         // the canvas used to take the picture
    };
  },
  mounted() {
    this.getMediaAccess();
  },
  beforeUnmount() {
    this.stopStream();
  },
  watch: {
    cameras: {
      handler() {
        this.availableCameras = [];
        this.cameras.forEach(c => {
          this.availableCameras.push({
            name: this.formatName(c.label),
            id: c.deviceId
          })
        });
      },
      deep: true,
    },
    source: function (id) {
      if (id === null) return;
      this.startCamera(id);
    }
  },
  methods: {
    formatName(deviceLabel) {
      const index = deviceLabel.lastIndexOf('(');
      return deviceLabel.substring(0, index);
    },
    hasPhotoSet() {
      return this.modelValue !== null && this.modelValue !== '';
    },
    getMediaAccess() {
      const constraints = {video: true};
      navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            let tracks = stream.getTracks();
            tracks.forEach(track => {
              track.stop();
            });
            this.setupMedia()
          })
          .catch(_error => {
            this.cameras = [];
          });
    },
    setupMedia() {
      navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            devices.forEach(device => {
              if (device.kind === 'videoinput') {
                this.cameras.push(device);
              }
            })
            if (!this.hasPhotoSet() && (this.cameras.length > 0)) {
              this.source = this.cameras[0].deviceId;
            }
          })
          .catch(_error => {
            this.cameras = [];
          });
    },
    startCamera(deviceId) {
      const constraints = {
        video: {
          deviceId: {
            exact: deviceId
          },
          height: this.height,
          width: this.width
        }
      };
      navigator.mediaDevices.getUserMedia(constraints)
          .then(stream => this.loadStream(stream))
          .catch(_error => {
            this.cameras = [];
          });
    },
    loadStream(stream) {
      if (this.$refs.video === null) {
        return;
      }
      this.$refs.video.srcObject = stream;
    },
    stopStream() {
      this.source = null;
      this.cameras.forEach(c => {
        const constraints = {
          video: {
            deviceId: {
              exact: c.deviceId
            }
          }
        };
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          stream.getTracks().forEach(track => {
            track.stop();
            stream.removeTrack(track);
          });
        }).catch(error => {
          console.log("Error stopping stream:", error);
        });
      });
      if (this.$refs.video) {
        this.$refs.video.srcObject = null;
      }
    },
    onTakePhoto() {
      if (this.hasPhotoSet()) {
        this.$emit('update:modelValue', null);
        if (this.source === null) {
          this.source = this.cameras[0].deviceId;
        }
        this.startCamera(this.source);
        return;
      }

      const canvas = document.createElement('canvas');
      canvas.height = this.height;
      canvas.width = this.width;
      this.canvas = canvas;

      const context = canvas.getContext('2d');
      context.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height)

      this.$refs.video.srcObject = null;
      this.stopStream();

      this.$emit('update:modelValue', canvas.toDataURL('image/png'));
    },
    onUploadPhoto() {
      this.$refs.imageUpload.click();
    },
    onPhotoSelected() {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.$emit('update:modelValue', reader.result);
      }, false);
      reader.readAsDataURL(this.$refs.imageUpload.files[0]);
    }
  }
}
</script>

<style scoped>
.video-overlay {
  position: absolute;
  text-align: center;
  z-index: 50000;
}

.video-overlay-buttons {
  position: absolute;
  bottom: 5px;
}
</style>
