<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Add Club">
      <form @submit.prevent="onSubmit">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-field p-col-12 p-md-12">
              <label for="shortName">Short name</label>
              <InputText id="shortName"
                         type="text"
                         v-model.trim="shortName"
                         @blur="v$.shortName.$touch()"
                         :class="{ 'p-invalid': v$.shortName.$error }"/>
              <small v-if="v$.shortName.$error" class="p-error">
                Short name is required and must be at least 3 characters.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <label for="fullName">Full Name</label>
              <InputText id="fullName"
                         type="text"
                         v-model.trim="fullName"
                         @blur="v$.fullName.$touch()"
                         :class="{ 'p-invalid': v$.fullName.$error }"/>
              <small v-if="v$.fullName.$error" class="p-error">
                Full name is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fee">Fee</label>
              <InputNumber id="fee"
                           v-model="fee"
                           showButtons
                           buttonLayout="horizontal"
                           :step="1.00"
                           :min="0"
                           decrementButtonClass="p-button-danger"
                           incrementButtonClass="p-button-success"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus"
                           mode="currency"
                           currency="EUR"/>
              <small v-if="v$.fee.$error" class="p-error">
                Fee should be a number greater than or equal to 0.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mandatory">Mandatory</label>
              <div style="padding-top: 5px;">
                <Checkbox id="mandatory"
                          v-model="mandatory"
                          :binary="true"/>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div align="right">
        <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel()"/>
        <Button label="Submit" @click="onSubmit()"/>
      </div>
    </Panel>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {minLength, minValue, numeric, required} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      shortName: null,
      fullName: null,
      mandatory: false,
      fee: 5.00,
    }
  },
  validations() {
    return {
      shortName: {
        required,
        minLength: minLength(3),
        $autoDirty: true
      },
      fullName: {
        required,
        minLength: minLength(1),
        $autoDirty: true
      },
      fee: {
        required,
        numeric,
        minValue: minValue(0),
        $autoDirty: true
      }
    }
  },
  methods: {
    onCancel() {
      this.$router.push({name: 'adminListClubs'});
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        shortName: this.shortName,
        fullName: this.fullName,
        fee: this.fee,
        mandatory: this.mandatory,
      }

      this.$store.dispatch('admin/addNewClub', request).then(_resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Club created',
          detail: 'Club was added successfully.',
          life: 3000
        });
        setTimeout(() => {
          this.$router.push({name: 'adminListClubs'});
        }, 1500);
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      })
    }
  }
}
</script>
