import {createRouter, createWebHistory} from 'vue-router';

import store from './store/index.js';

import AdminLogin from './components/admin/Login.vue';
import AdminMain from './components/admin/Main.vue';
import AdminVerify from './components/admin/Verify.vue';
import AdminAddNewAdmin from './components/admin/admins/Add.vue';
import AdminListAdmins from './components/admin/admins/List.vue';
import AdminAddNewMember from './components/admin/members/Add.vue';
import AdminListMembers from './components/admin/members/List.vue';
import AdminAddNewClub from './components/admin/clubs/Add.vue';
import AdminListClubs from './components/admin/clubs/List.vue';
import AdminAddNewAffiliatedGroup from './components/admin/affiliated-groups/Add.vue';
import AdminListAffiliatedGroups from './components/admin/affiliated-groups/List.vue';
import AdminProfileUpdate from './components/admin/profile/Update.vue';
import AdminRenewals from './components/admin/renewals/Renewals.vue';

import AffiliatedGroupAdminAddNewMember from './components/admin/affiliated-group-admin/Add.vue';
import AffiliatedGroupAdminListMembers from './components/admin/affiliated-group-admin/List.vue';

import AdminInsuranceReport from './components/admin/reports/InsuranceReport.vue';
import AdminEmailReport from './components/admin/reports/EmailList.vue';

import MemberLogin from './components/members/Login.vue';
import MemberVerify from './components/members/Verify.vue';
import MemberMain from './components/members/Main.vue';
import MemberUpdateDetails from './components/members/UpdateDetails.vue';
import MemberMembership from './components/members/Membership.vue';
import MemberLicenceAndInsurance from './components/members/LicencesAndInsurance.vue';
import MemberUpdateLicences from './components/members/UpdateLicences.vue';
import MemberRenewalReceipt from './components/members/RenewalReceipt.vue';

const routes = [
    {
        path: '/',
        redirect: '/member',
    },
    {
        name: 'adminLogin',
        path: '/admin/login',
        component: AdminLogin
    },
    {
        name: 'adminVerify',
        path: '/admin/verify',
        component: AdminVerify,
        beforeEnter(to, from, next) {
            if (store.getters['admin/username']) {
                next();
            } else {
                next({name: 'adminLogin'})
            }
        }
    },
    {
        name: 'adminMain',
        path: '/admin',
        component: AdminMain,
        children: [
            {
                name: 'adminUpdateProfile',
                path: 'profile/update',
                component: AdminProfileUpdate
            },
            {
                name: 'adminListMembers',
                path: '',
                alias: 'members/list',
                component: AdminListMembers,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'affiliatedGroupAdminListMembers',
                path: 'group-members/list',
                component: AffiliatedGroupAdminListMembers,
                meta: {
                    requiresAmacsAdmin: false
                }
            },
            {
                name: 'adminAddNewMember',
                path: 'members/add',
                component: AdminAddNewMember,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'affiliatedGroupAdminAddNewMember',
                path: 'group-members/add',
                component: AffiliatedGroupAdminAddNewMember,
                meta: {
                    requiresAmacsAdmin: false
                }
            },
            {
                name: 'adminAddNewAdmin',
                path: 'administrators/add',
                component: AdminAddNewAdmin,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminListAdmins',
                path: 'administrators/list',
                component: AdminListAdmins,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminAddNewClub',
                path: 'clubs/add',
                component: AdminAddNewClub,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminListClubs',
                path: 'clubs/list',
                component: AdminListClubs,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminAddNewAffiliatedGroup',
                path: 'affiliated-groups/add',
                component: AdminAddNewAffiliatedGroup,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminListAffiliatedGroups',
                path: 'affiliated-groups/list',
                component: AdminListAffiliatedGroups,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminInsuranceReport',
                path: 'reports/insurance',
                component: AdminInsuranceReport,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminEmailReport',
                path: 'reports/emails',
                component: AdminEmailReport,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                name: 'adminRenewals',
                path: 'administration/renewals',
                component: AdminRenewals,
                meta: {
                    requiresAmacsAdmin: true
                }
            },
            {
                path: ':notFound(.*)',
                redirect: '/admin'
            }
        ],
        beforeEnter(to, from, next) {
            if (store.getters['admin/token']) {
                store.dispatch('admin/validateSession').then(_ => {
                    if ((to.meta.requiresAmacsAdmin === true) && (store.getters['admin/role'] !== 'amacs'))  {
                        next({name: 'affiliatedGroupAdminListMembers'});
                        return;
                    }
                    next();
                }).catch(_ => {
                    next({name: 'adminLogin'});
                })
            } else {
                next({name: 'adminLogin'});
            }
        }
    },
    {
        name: 'memberMain',
        path: '/member',
        component: MemberMain,
        beforeEnter(to, from, next) {
            if (store.getters['members/isLoggedIn']) {
                next();
            } else {
                next({name: 'memberLogin'});
            }
        }
    },
    {
        name: 'memberLogin',
        path: '/member/login',
        component: MemberLogin,
    },
    {
        name: 'memberVerify',
        path: '/member/verify',
        component: MemberVerify,
        beforeEnter(to, from, next) {
            if (store.getters['members/isLoggedIn']) {
                next();
            } else {
                next({name: 'memberLogin'});
            }
        }
    },
    {
        name: 'memberUpdateDetails',
        path: '/member/update-details',
        component: MemberUpdateDetails,
        props: true,
        beforeEnter(to, from, next) {
            if (store.getters['members/isLoggedIn']) {
                next();
            } else {
                next({name: 'memberLogin'});
            }
        }
    },
    {
        name: 'memberMembership',
        path: '/member/membership',
        component: MemberMembership,
        beforeEnter(to, from, next) {
            if (store.getters['members/isLoggedIn']) {
                next();
            } else {
                next({name: 'memberLogin'});
            }
        }
    },
    {
        name: 'memberLicenceAndInsurance',
        path: '/member/licence-and-insurance',
        component: MemberLicenceAndInsurance,
        beforeEnter(to, from, next) {
            if (store.getters['members/isLoggedIn']) {
                next();
            } else {
                next({name: 'memberLogin'});
            }
        }
    },
    {
        name: 'memberUpdateLicences',
        path: '/member/update-licences',
        component: MemberUpdateLicences,
        beforeEnter(to, from, next) {
            if (store.getters['members/isLoggedIn']) {
                next();
            } else {
                next({name: 'memberLogin'});
            }
        }
    },
    {
        name: 'receipt',
        path: '/member/renewal-receipt',
        component: MemberRenewalReceipt,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/member'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
