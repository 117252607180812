<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Card>
      <template #content>
        <DataTable :value="renewalYears">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Renewal Years</h5>
              <span class="p-input-icon-left">
                <Button label="Add"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="onAddRenewalYear()"></Button>
              </span>
            </div>
          </template>
          <Column field="year" header="Year"></Column>
          <Column field="enabled" headerStyle="min-width: 4rem; text-align: center"
                  bodyStyle="text-align: right; overflow: visible">
            <template #body="slotProps">
              <Button :label="slotProps.data.enabled ? 'Disable' : 'Enable'"
                      :class="slotProps.data.enabled ? 'p-button-danger' : 'p-button-success'"
                      @click="onSetRenewalYearStatus(slotProps.data.year, !slotProps.data.enabled)"></Button>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <Toast/>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
export default {
  data() {
    return {
      renewalYears: []
    }
  },
  mounted() {
    this.fetchRenewalYears();
  },
  methods: {
    fetchRenewalYears() {
      this.$store.dispatch('data/fetchRenewalYears').then(data => {
        this.renewalYears = data;
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    onAddRenewalYear() {
      const lastYear = this.renewalYears[0].year;
      const newYear = (parseInt(lastYear) + 1).toString();

      this.$confirm.require({
        message: `This will allow members to renew their membership for ${newYear}. Are you sure you want to continue?`,
        header: `Allow renewals for ${newYear}`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('admin/createRenewalYear', newYear).then(_data => {
            this.fetchRenewalYears();
          }).catch(error => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
              life: 3000
            });
          });
        }
      });
    },
    onSetRenewalYearStatus(year, enabled) {
      const message = enabled ? `This will allow members to renew their membership for ${year}. Are you sure you want to continue?` :
          `This will stop members from renewing their membership for ${year}. Are you sure you want to continue?`;

      const data = {
        'year': year,
        'status': enabled
      }

      this.$confirm.require({
        message: message,
        header: enabled ? `Enable renewals for ${year}` : `Disable renewals for ${year}`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('admin/setRenewalYearStatus', data).then(_data => {
            this.fetchRenewalYears();
          }).catch(error => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
              life: 3000
            });
          });
        }
      });
    }
  }
}
</script>
