<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Add New Administrator">
      <form @submit.prevent="onSubmit">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-field p-col-12 p-md-4">
              <label for="username">Username</label>
              <InputText id="username"
                         type="text"
                         v-model.trim="username"
                         @blur="v$.username.$touch()"
                         autocomplete="new-username"
                         :class="{ 'p-invalid': v$.username.$error }"/>
              <small v-if="v$.username.$error" class="p-error">
                Username is required and must be at least 5 characters.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="password">Password</label>
              <Password id="password"
                        v-model.trim="password"
                        @blur="v$.password.$touch()"
                        autocomplete="new-password"
                        :class="{ 'p-invalid': v$.password.$error }"/>
              <small v-if="v$.password.$error" class="p-error">
                Password is required and should be at least 6 characters long.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="group">Group</label>
              <Dropdown id="group"
                        v-model="group"
                        :options="availableGroups"
                        @blur="v$.group.$touch()"
                        :class="{ 'p-invalid': v$.group.$error }"/>
              <small v-if="v$.group.$error" class="p-error">
                Group is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="firstName">First Name</label>
              <InputText id="firstName"
                         type="text"
                         v-model.trim="firstName"
                         @blur="v$.firstName.$touch()"
                         :class="{ 'p-invalid': v$.firstName.$error }"/>
              <small v-if="v$.firstName.$error" class="p-error">
                First name is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="lastName">Last Name</label>
              <InputText id="lastName"
                         type="text"
                         v-model.trim="lastName"
                         @blur="v$.lastName.$touch()"
                         :class="{ 'p-invalid': v$.lastName.$error }"/>
              <small v-if="v$.lastName.$error" class="p-error">
                Last name is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mobileNumber">Mobile Number</label>
              <div class="p-inputgroup">
                <Dropdown id="countryCode"
                          v-model="mobileNumberCountryCode"
                          :options="countries"
                          optionLabel="code"
                          optionValue="code"
                          :filter="true"
                          style="max-width: 120px">
                  <template #option="slotProps">
                    <div class="country-item-value">
                      <img src="../../../assets/images/flag_placeholder.png"
                           :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                      <span>{{ slotProps.option.code }}</span>
                    </div>
                  </template>
                </Dropdown>
                <InputText id="mobile-number"
                           type="number"
                           v-model.trim="mobileNumber"
                           :class="{'p-invalid':v$.mobileNumber.$error}"/>
              </div>
              <small v-if="v$.mobileNumber.$error" class="p-error">
                Mobile number is invalid.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="emailAddress">Email</label>
              <InputText id="emailAddress"
                         type="email"
                         v-model.trim="emailAddress"
                         @blur="v$.emailAddress.$touch()"
                         :class="{ 'p-invalid': v$.emailAddress.$error }"/>
              <small v-if="v$.emailAddress.$error" class="p-error">
                A valid email address is required.
              </small>
            </div>
          </div>
        </div>
      </form>
      <div align="right">
        <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel()"/>
        <Button label="Submit" @click="onSubmit()"/>
      </div>
    </Panel>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {email, minLength, numeric, required} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      availableGroups: null,
      countries: [],

      username: null,
      password: null,
      group: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      mobileNumberCountryCode: '+356',
      mobileNumber: null
    }
  },
  validations() {
    const validMobileNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }
    return {
      username: {
        required,
        minLength: minLength(5),
        $autoDirty: true
      },
      password: {
        required,
        minLength: minLength(6),
        $autoDirty: true
      },
      group: {
        required,
        minLength: minLength(3),
        $autoDirty: true
      },
      firstName: {
        required,
        $autoDirty: true,
      },
      lastName: {
        required,
        $autoDirty: true,
      },
      mobileNumber: {
        required,
        numeric,
        validMobileNumber,
        $autoDirty: true,
      },
      emailAddress: {
        required,
        email,
        $autoDirty: true,
      },
    }
  },
  mounted() {
    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    this.fetchAffiliatedGroups();
  },
  methods: {
    fetchAffiliatedGroups() {
      this.$store.dispatch('data/fetchAffiliatedGroups').then(groups => {
        this.availableGroups = ['AMACS'].concat(groups.map(g => g['short_name'].toUpperCase()));
      }).catch(_error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Could not fetch affiliated groups',
          life: 3000
        });
      })
    },
    sendCompleteIfSet(prefix, number) {
      if ((number === null) || (number === '')) {
        return null;
      }
      return `${prefix} ${number}`;
    },
    onCancel() {
      this.$router.push({name: 'adminListAdmins'});
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        username: this.username,
        password: this.password,
        group: this.group,
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.emailAddress,
        mobileNumber: this.sendCompleteIfSet(this.mobileNumberCountryCode, this.mobileNumber)
      }

      this.$store.dispatch('admin/addNewAdmin', request).then(_resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Account created',
          detail: 'Administrator account was added successfully.',
          life: 3000
        });
        setTimeout(() => {
          this.$router.push({name: 'adminListAdmins'});
        }, 1500);
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      })
    }
  }
}
</script>
