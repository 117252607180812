<template>
  <TabView>
    <TabPanel header="Personal Details">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-3 p-md-4">
          <MemberPhoto id="photo" v-model="photo"/>
          <small v-if="v$.photo.$error" class="p-error">
            Photo is required.
          </small>
        </div>
        <div class="p-lg-9 p-md-8">
          <div class="p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label for="membershipNumber">AMACS Membership Number</label>
              <InputText id="membershipNumber"
                         disabled="true"
                         type="text"
                         v-model="membershipNumber"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="idCardNumber">ID Card Number</label>
              <InputText id="idCardNumber"
                         type="text"
                         v-model.trim="idCardNumber"
                         @blur="padIdCardNumber"
                         :class="{ 'p-invalid': v$.idCardNumber.$error }"/>
              <small v-if="v$.idCardNumber.$error" class="p-error">
                ID card number must be made up of maximum 7 digits followed by A, P, M, G, Z, H, B or L.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="title">Title</label>
              <InputText id="title"
                         type="text"
                         v-model.trim="title"
                         @blur="v$.title.$touch()"
                         :class="{ 'p-invalid': v$.title.$error }"/>
              <small v-if="v$.title.$error" class="p-error">
                Title is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="firstName">First Name</label>
              <InputText id="firstName"
                         type="text"
                         v-model.trim="firstName"
                         @blur="v$.firstName.$touch()"
                         :class="{ 'p-invalid': v$.firstName.$error }"/>
              <small v-if="v$.firstName.$error" class="p-error">
                First name is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="lastName">LastName</label>
              <InputText id="lastName"
                         type="text"
                         v-model.trim="lastName"
                         @blur="v$.lastName.$touch()"
                         :class="{ 'p-invalid': v$.lastName.$error }"/>
              <small v-if="v$.lastName.$error" class="p-error">
                Last name is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="gender">Gender</label>
              <Dropdown id="gender"
                        v-model="gender"
                        :options="availableGenders"
                        optionLabel="name"
                        optionValue="code"/>
              <small v-if="v$.gender.$error" class="p-error">
                Gender is required
              </small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="dateOfBirth">Date of Birth</label>
              <Calendar id="popup" class="form-element"
                        placeholder="dd/mm/yyyy"
                        dateFormat="dd/mm/yy"
                        :monthNavigator="true"
                        :yearNavigator="true"
                        :yearRange="yearRange"
                        v-model="dateOfBirth"
                        @blur="v$.dateOfBirth.$touch()"
                        :class="{ 'p-invalid': v$.dateOfBirth.$error }">
              </Calendar>
              <small v-if="v$.dateOfBirth.$error" class="p-error">
                Date of birth is required and must be entered as dd/mm/yyyy.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="placeOfBirth">Place of Birth</label>
              <InputText id="placeOfBirth"
                         type="text"
                         v-model.trim="placeOfBirth"
                         @blur="v$.placeOfBirth.$touch()"
                         :class="{ 'p-invalid': v$.placeOfBirth.$error }"/>
              <small v-if="v$.placeOfBirth.$error" class="p-error">
                Place of birth is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="deceased">Deceased</label><br/>
              <Checkbox id="deceased"
                        v-model="deceased"
                        :binary="true"
                        style="margin-top: 6px"/>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="vip">VIP</label><br/>
              <Checkbox id="vip"
                        v-model="vip"
                        :binary="true"
                        style="margin-top: 6px"/>
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="streetAddress">Street Address</label>
          <InputText id="streetAddress"
                     type="text"
                     v-model.trim="streetAddress"
                     @blur="v$.streetAddress.$touch()"
                     :class="{ 'p-invalid': v$.streetAddress.$error }"/>
          <small v-if="v$.streetAddress.$error" class="p-error">
            Street address is required.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="town">Town/Village</label>
          <InputText id="town"
                     type="text"
                     v-model.trim="town"
                     @blur="v$.town.$touch()"
                     :class="{ 'p-invalid': v$.town.$error }"/>
          <small v-if="v$.town.$error" class="p-error">
            Town/Village address is required.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="postCode">Post Code</label>
          <InputText id="postCode"
                     type="text"
                     v-model.trim="postCode"
                     @blur="v$.postCode.$touch()"
                     :class="{ 'p-invalid': v$.postCode.$error }"/>
          <small v-if="v$.postCode.$error" class="p-error">
            Post code is required.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="mobileNumber">Mobile Number</label>
          <div class="p-inputgroup">
            <Dropdown id="countryCode"
                      v-model="mobileNumberCountryCode"
                      :options="countries"
                      optionLabel="code"
                      optionValue="code"
                      :filter="true"
                      style="max-width: 120px">
              <template #option="slotProps">
                <div class="country-item-value">
                  <img src="../../../assets/images/flag_placeholder.png"
                       :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                  <span>{{ slotProps.option.code }}</span>
                </div>
              </template>
            </Dropdown>
            <InputText id="mobile-number"
                       type="number"
                       v-model.trim="mobileNumber"
                       :class="{'p-invalid':v$.mobileNumber.$error}"/>
          </div>
          <small v-if="v$.mobileNumber.$error" class="p-error">
            Mobile number is invalid.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="emailAddress">Email</label>
          <InputText id="emailAddress"
                     type="email"
                     v-model.trim="emailAddress"
                     @blur="v$.emailAddress.$touch()"
                     :class="{ 'p-invalid': v$.emailAddress.$error }"/>
          <small v-if="v$.emailAddress.$error" class="p-error">
            A valid email address is required.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="homePhone">Home Phone</label>
          <div class="p-inputgroup">
            <Dropdown id="countryCode"
                      v-model="homePhoneCountryCode"
                      :options="countries"
                      optionLabel="code"
                      optionValue="code"
                      :filter="true"
                      style="max-width: 120px">
              <template #option="slotProps">
                <div class="country-item-value">
                  <img src="../../../assets/images/flag_placeholder.png"
                       :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                  <span>{{ slotProps.option.code }}</span>
                </div>
              </template>
            </Dropdown>
            <InputText id="homePhone"
                       type="number"
                       v-model.trim="homePhone"
                       @blur="v$.homePhone.$touch()"
                       :class="{ 'p-invalid': v$.homePhone.$error }"/>
          </div>
          <small v-if="v$.homePhone.$error" class="p-error">
            Home phone is not valid.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="workPhone">Work Phone</label>
          <div class="p-inputgroup">
            <Dropdown id="countryCode"
                      v-model="workPhoneCountryCode"
                      :options="countries"
                      optionLabel="code"
                      optionValue="code"
                      :filter="true"
                      style="max-width: 120px">
              <template #option="slotProps">
                <div class="country-item-value">
                  <img src="../../../assets/images/flag_placeholder.png"
                       :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                  <span>{{ slotProps.option.code }}</span>
                </div>
              </template>
            </Dropdown>
            <InputText id="workPhone"
                       type="number"
                       v-model.trim="workPhone"
                       @blur="v$.workPhone.$touch()"
                       :class="{ 'p-invalid': v$.workPhone.$error }"/>
          </div>
          <small v-if="v$.workPhone.$error" class="p-error">
            Work phone is not valid.
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="occupation">Occupation</label>
          <InputText id="occupation" type="text" v-model.trim="occupation"/>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="employer">Employer</label>
          <InputText id="employer" type="text" v-model.trim="employer"/>
        </div>
      </div>
      <Divider/>
      <div align="right">
        <Button label="Update Profile" @click="onUpdateProfile" class="p-mt-2"/>
      </div>
    </TabPanel>
    <TabPanel header="Clubs, Insurance, and Licences">
      <DataTable :value="membershipsAndLicences"
                 v-model:expandedRows="expandedMembershipsAndLicencesRows"
                 dataKey="year">
        <Column :expander="true" headerStyle="width: 3rem"/>
        <Column field="year" header="Year" sortable></Column>
        <Column headerStyle="min-width: 8rem; text-align: center" bodyStyle="text-align: right; overflow: visible">
          <template #body="member">
            <Button class="p-mr-2"
                    type="button"
                    icon="pi pi-id-card"
                    @click="onPrintDocument(member.data.year, 'mc')"/>
            <Button type="button"
                    icon="pi pi-file"
                    :disabled="!hasInsuranceInYear(member.data.year)"
                    @click="onPrintDocument(member.data.year, 'ic')"/>
          </template>
        </Column>
        <template #expansion="data">
          <div class="p-grid">
            <div class="p-md-6">
              <Fieldset legend="Clubs">
                <span v-for="club in data['data']['clubs']" :key="club.id">
                  <div class="p-field-checkbox">
                    <Checkbox name="club"
                              :id="club.id"
                              :value="club.id"
                              :binary="true"
                              :disabled="club.mandatory"
                              v-model="club.member"
                              @change="onClubChange(data['data'].year, club.shortName, club.member)"/>
                    <label :for="club.id">{{ club.name }}</label>
                  </div>
                </span>
              </Fieldset>
            </div>
            <div class="p-md-6">
              <Fieldset legend="Licences">
                <span v-for="licence in data['data']['licences']" :key="licence.id">
                  <div class="p-field-checkbox">
                    <Checkbox name="licence"
                              :id="licence.id"
                              :value="licence.id"
                              :binary="true"
                              v-model="licence.obtained"
                              @change="onLicenceChange(data['data'].year, licence.id, licence.obtained)"/>
                    <label :for="licence.id">{{ licenceIdToName(licence.id) }}</label>
                  </div>
                </span>
              </Fieldset>
            </div>
            <div class="p-md-6">
              <Fieldset legend="Affiliated Groups">
                <span v-for="group in data['data']['affiliatedGroups']" :key="group.id">
                  <div class="p-field-checkbox">
                    <Checkbox name="group"
                              :id="group.id"
                              :value="group.id"
                              :binary="true"
                              v-model="group.member"
                              @change="onAffiliatedGroupChange(data['data'].year, group.shortName, group.member)"/>
                    <label :for="group.id">{{ group.name }}</label>
                  </div>
                </span>
              </Fieldset>
            </div>
            <div class="p-md-6">
              <Fieldset legend="Insurance">
                <span v-for="insurance in data['data']['insurance']" :key="insurance.id">
                  <div class="p-field-checkbox">
                    <Checkbox name="insurance"
                              :id="insurance.id"
                              :value="insurance.id"
                              :binary="true"
                              v-model="insurance.bought"
                              :disabled="shouldDisableInsuranceCheckbox(data['data']['insurance'], insurance.id)"
                              @change="onInsuranceChange(data['data'].year, insurance.id, insurance.bought)"/>
                    <label :for="insurance.id">{{ insurance.name }}</label>
                    <InputText id="insuredElsewhereName"
                               v-if="insurance.id === 'insured_elsewhere'"
                               :disabled="!insurance.bought"
                               type="text"
                               class="p-ml-2"
                               v-model.trim="insurance.otherAgencyName"
                               @change="onOtherAgencyNameChange(data['data'].year)"/>
                  </div>
                </span>
              </Fieldset>
            </div>
          </div>
        </template>
      </DataTable>
    </TabPanel>
  </TabView>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {email, helpers, numeric, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'AdminUpdateMemberDetails',
  props: {
    member: {
      type: Object,
      default: null
    },
  },
  emits: ['error', 'infoMessage'],
  data() {
    const today = new Date();
    const allGenders = [
      {name: 'Male', code: 'M'},
      {name: 'Female', code: 'F'},
      {name: 'Unspecified', code: 'X'}
    ];
    return {
      yearRange: `${today.getFullYear() - 100}:${today.getFullYear()}`,
      availableGenders: allGenders,
      countries: [],

      membershipNumber: this.member.membershipNumber,
      idCardNumber: this.member.idCardNumber,
      title: this.member.title,
      firstName: this.member.firstName,
      lastName: this.member.lastName,
      gender: this.member.gender,
      dateOfBirth: this.member.dateOfBirth,
      placeOfBirth: this.member.placeOfBirth,
      deceased: this.member.deceased,
      vip: this.member.vip,
      streetAddress: this.member.streetAddress,
      town: this.member.town,
      postCode: this.member.postCode,
      mobileNumber: this.numberOrDefault(this.member.mobileNumber),
      mobileNumberCountryCode: this.countryCodeOrDefault(this.member.mobileNumber),
      emailAddress: this.member.emailAddress,
      homePhone: this.numberOrDefault(this.member.homePhone),
      homePhoneCountryCode: this.countryCodeOrDefault(this.member.homePhone),
      workPhone: this.numberOrDefault(this.member.workPhone),
      workPhoneCountryCode: this.countryCodeOrDefault(this.member.workPhone),
      occupation: this.member.occupation,
      employer: this.member.employer,
      photo: this.member.photo,

      membershipsAndLicences: [],
      expandedMembershipsAndLicencesRows: null
    };
  },
  watch: {
    membershipsAndLicences: function (val) {
      this.expandedMembershipsAndLicencesRows = val.filter(x => x.year);
    }
  },
  validations() {
    const idCardNumber = (value) => /^[0-9]{3,7}[AaBbGgHhLlMmPpZz]/.test(value);
    const date = (value) => value instanceof Date || /\d{2}\/\d{2}\/\d{4}/.test(value);

    const validMobileNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    const validHomeNumber = (value) => {
      if (!helpers.req(value) && (value == null || value.length === 0)) return true;
      if (this.homePhoneCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    const validWorkNumber = (value) => {
      if (!helpers.req(value) && (value == null || value.length === 0)) return true;
      if (this.workPhoneCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    return {
      idCardNumber: {
        required,
        idCardNumber,
        $autoDirty: true,
      },
      title: {
        required,
        $autoDirty: true,
      },
      firstName: {
        required,
        $autoDirty: true,
      },
      lastName: {
        required,
        $autoDirty: true,
      },
      gender: {
        required,
        $autoDirty: true,
      },
      dateOfBirth: {
        required,
        date,
        $autoDirty: true,
      },
      placeOfBirth: {
        required,
        $autoDirty: true,
      },
      streetAddress: {
        required,
        $autoDirty: true,
      },
      town: {
        required,
        $autoDirty: true,
      },
      postCode: {
        required,
        $autoDirty: true,
      },
      mobileNumber: {
        required,
        numeric,
        validMobileNumber,
        $autoDirty: true,
      },
      emailAddress: {
        email,
        $autoDirty: true,
      },
      homePhone: {
        numeric,
        validHomeNumber,
        $autoDirty: true,
      },
      workPhone: {
        numeric,
        validWorkNumber,
        $autoDirty: true,
      },
      photo: {
        required,
      }
    };
  },
  mounted() {
    const req = {
      membershipNumber: this.member.membershipNumber
    };

    this.$store.dispatch('admin/fetchMemberPhoto', req).then(resp => {
      this.photo = resp.photo
    }).catch(error => {
      this.$emit('error', error);
    });

    this.$store.dispatch('admin/fetchMembershipsAndLicences', req).then(resp => {
      this.membershipsAndLicences = resp.sort((a, b) => {
        return (parseInt(a.year) - parseInt(b.year)) * -1;
      });
    }).catch(error => {
      this.$emit('error', error);
    });

    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

  },
  methods: {
    countryCodeOrDefault(number) {
      if ((number === null) || (number === '')) {
        return '+356';
      }
      return number.split(' ')[0];
    },
    numberOrDefault(number) {
      if ((number === null) || (number === '')) {
        return '';
      }
      return number.split(' ')[1];
    },
    sendCompleteIfSet(prefix, number) {
      if ((number === null) || (number === '')) {
        return null;
      }
      return `${prefix} ${number}`;
    },
    padIdCardNumber() {
      this.v$.idCardNumber.$touch();
      if (this.v$.idCardNumber.$error) {
        return;
      }
      this.idCardNumber = this.idCardNumber.padStart(8, '0');
    },
    licenceIdToName(id) {
      switch (id) {
        case "collector_a":
          return "Collector Licence A";
        case "collector_a_special":
          return "Collector Licence A (Special)";
        case "target_a":
          return "Target Shooter Licence A";
        case "target_a_special":
          return "Target Shooter Licence A (Special)";
        case "target_a_cb":
          return "Target Shooter Licence A (Crossbow)";
        case "rco":
          return "Range Conducting Officer";
        case "target_b_ts":
          return "Target Shooter Licence B (Tactical Shotguns)";
        case "target_b_ag":
          return "Target Shooter Licence B (Air Guns)";
        case "target_b_cp":
          return "Target Shooter Licence B (Clay Pigeon)";
        case "target_b_ml":
          return "Target Shooter Licence B (Muzzle Loaders)"
      }
    },
    shouldDisableInsuranceCheckbox(insuranceInfo, currentInsuranceId) {
      if (currentInsuranceId !== 'personal_accident') {
        return false;
      }

      return insuranceInfo.find(x => x.id === 'insured_elsewhere').bought;
    },
    onUpdateProfile() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        membershipNumber: this.membershipNumber,
        idCardNumber: this.idCardNumber,
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender,
        dateOfBirth: this.dateOfBirth,
        placeOfBirth: this.placeOfBirth,
        deceased: this.deceased,
        vip: this.vip,
        streetAddress: this.streetAddress,
        town: this.town,
        postCode: this.postCode,
        mobileNumber: this.sendCompleteIfSet(this.mobileNumberCountryCode, this.mobileNumber),
        emailAddress: this.emailAddress,
        homePhone: this.sendCompleteIfSet(this.homePhoneCountryCode, this.homePhone),
        workPhone: this.sendCompleteIfSet(this.workPhoneCountryCode, this.workPhone),
        occupation: this.occupation,
        employer: this.employer,
        photo: this.photo
      };

      this.$store.dispatch('admin/updateMemberDetails', request).then(_resp => {
        this.$emit('infoMessage', {
          title: 'Member updated',
          message: 'Member details were updated successfully.'
        })
      }).catch(error => {
        this.$emit('error', error);
      });
    },
    onClubChange(year, shortName, isMember) {
      const req = {
        membershipNumber: this.membershipNumber,
        clubShortName: shortName,
        year: year
      }

      if (isMember) {
        this.$store.dispatch('admin/addMemberToClub', req).then(_resp => {
        }).catch(error => {
          this.$emit('error', error);
        });
      } else {
        this.$store.dispatch('admin/removeMemberFromClub', req).then(_resp => {
        }).catch(error => {
          this.$emit('error', error);
        });
      }
    },
    calculateLicenceToRemove(year, obtainedLicenceId) {
      if (obtainedLicenceId === 'target_a') {
        return 'target_a_special';
      } else if (obtainedLicenceId === 'target_a_special') {
        return 'target_a';
      } else if (obtainedLicenceId === 'collector_a') {
        return 'collector_a_special';
      } else if (obtainedLicenceId === 'collector_a_special') {
        return 'collector_a';
      }
      return '';
    },
    onLicenceChange(year, licenceId, hasObtained) {
      const req = {
        membershipNumber: this.membershipNumber,
        licenceId: licenceId,
        year: year
      }

      if (hasObtained) {
        const licenceToRemove = this.calculateLicenceToRemove(year, licenceId);
        if (licenceToRemove !== '') {
          const allLicences = this.membershipsAndLicences.find(ml => ml.year === year).licences;
          allLicences.find(l => l.id === licenceToRemove).obtained = false;

          req.licenceId = licenceToRemove;
          this.$store.dispatch('admin/removeMemberLicence', req).then(_resp => {
            req.licenceId = licenceId;
            this.$store.dispatch('admin/addMemberLicence', req).then(_resp => {
            }).catch(error => {
              this.$emit('error', error);
            });
          }).catch(error => {
            this.$emit('error', error);
          });
          return;
        }

        this.$store.dispatch('admin/addMemberLicence', req).then(_resp => {
        }).catch(error => {
          this.$emit('error', error);
        });
      } else {
        this.$store.dispatch('admin/removeMemberLicence', req).then(_resp => {
        }).catch(error => {
          this.$emit('error', error);
        });
      }
    },
    onAffiliatedGroupChange(year, shortName, isMember) {
      const req = {
        membershipNumber: this.membershipNumber,
        affiliatedGroupShortName: shortName,
        year: year
      }

      if (isMember) {
        this.$store.dispatch('admin/addMemberToAffiliatedGroup', req).then(_resp => {
        }).catch(error => {
          this.$emit('error', error);
        });
      } else {
        this.$store.dispatch('admin/removeMemberFromAffiliatedGroup', req).then(_resp => {
        }).catch(error => {
          this.$emit('error', error);
        });
      }
    },
    onInsuranceChange(year, changedInsuranceId, bought) {
      const insurance = this.membershipsAndLicences.find(ml => ml.year === year).insurance;

      const requiresInsuranceCoverage = this.hasTargetShootingLicence(year);
      if (changedInsuranceId === 'insured_elsewhere') {
        if (bought) {
          insurance.filter(i => i.id !== 'insured_elsewhere').forEach(i => i.bought = false);
        } else if (requiresInsuranceCoverage) {
          insurance.find(i => i.id === 'third_party').bought = true;
        }
      } else if (changedInsuranceId === 'third_party') {
        if (bought) {
          insurance.find(i => i.id === 'insured_elsewhere').bought = false;
        } else if (requiresInsuranceCoverage) {
          insurance.find(i => i.id === 'personal_accident').bought = false;
          insurance.find(i => i.id === 'insured_elsewhere').bought = true;
        }
      }

      const req = {
        year: year,
        membershipNumber: this.membershipNumber,
        insuredElsewhere: insurance.find(i => i.id === 'insured_elsewhere').bought,
        thirdParty: insurance.find(i => i.id === 'third_party').bought,
        personalAccident: insurance.find(i => i.id === 'personal_accident').bought
      };

      this.$store.dispatch('admin/updateMemberInsurance', req).then(_resp => {
      }).catch(error => {
        this.$emit('error', error);
      });
    },
    onOtherAgencyNameChange(year) {
      const insuredElsewhereInfo = this.membershipsAndLicences.find(ml => ml.year === year)
          .insurance
          .find(i => i.id === 'insured_elsewhere');

      const req = {
        year: year,
        membershipNumber: this.membershipNumber,
        otherAgencyName: insuredElsewhereInfo.otherAgencyName
      };

      this.$store.dispatch('admin/updateMemberElsewhereInsuranceAgency', req).then(_resp => {
      }).catch(error => {
        this.$emit('error', error);
      });
    },
    hasTargetShootingLicence(year) {
      const licences = this.membershipsAndLicences.find(ml => ml.year === year).licences;
      return licences.find(l => (l.id.startsWith('target_a') || l.id.startsWith('target_b') || l.id === 'rco') && l.obtained) !== undefined;
    },
    hasInsuranceInYear(year) {
      const insurance = this.membershipsAndLicences.find(ml => ml.year === year).insurance;
      return insurance.find(i => i.id !== 'insured_elsewhere').bought;
    },
    onPrintDocument(year, documentType) {
      const token = this.$store.getters['admin/token'];
      window.open(`/v1/print/member-document?type=${documentType}&member=${this.membershipNumber}&year=${year}&token=${token}`)
    },
  }
}
</script>
