export default {
    username(state) {
        return state.username;
    },
    mobileNumber(state) {
        return state.mobileNumber;
    },
    emailAddress(state) {
        return state.emailAddress;
    },
    token(state) {
        return state.token;
    },
    role(state) {
        return state.role;
    }
}
