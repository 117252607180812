<template>
  <Menubar :model="items">
    <template #start>
      <img alt="logo" src="../../assets/images/amacs-logo.png" height="40" class="p-mr-2">
    </template>
  </Menubar>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          label: 'Members',
          icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Add',
              icon: 'pi pi-fw pi-user-plus',
              to: '/admin/members/add',
              visible: () => {
                return this.$store.getters['admin/role'] === 'amacs';
              },
            },
            {
              label: 'Add',
              icon: 'pi pi-fw pi-user-plus',
              to: '/admin/group-members/add',
              visible: () => {
                return this.$store.getters['admin/role'] !== 'amacs';
              },
            },
            {
              label: 'List',
              icon: 'pi pi-fw pi-users',
              to: '/admin/members/list',
              visible: () => {
                return this.$store.getters['admin/role'] === 'amacs';
              },
            },
            {
              label: 'List',
              icon: 'pi pi-fw pi-users',
              to: '/admin/group-members/list',
              visible: () => {
                return this.$store.getters['admin/role'] !== 'amacs';
              },
            }
          ]
        },
        {
          label: 'Reports',
          icon: 'pi pi-fw pi-chart-line',
          visible: () => {
            return this.$store.getters['admin/role'] === 'amacs';
          },
          items: [
            {
              label: 'Membership',
              icon: 'pi pi-fw pi-users',
            },
            {
              label: 'Insurance',
              icon: 'pi pi-fw pi-book',
              to: '/admin/reports/insurance'
            },
            {
              label: 'Email List',
              icon: 'pi pi-fw pi-envelope',
              to: '/admin/reports/emails'
            }
          ]
        },
        {
          label: 'Administration',
          icon: 'pi pi-fw pi-cog',
          visible: () => {
            return this.$store.getters['admin/role'] === 'amacs';
          },
          items: [
            {
              label: 'Administrators',
              icon: 'pi pi-fw pi-key',
              to: '/admin/administrators/list'
            },
            {
              label: 'Clubs',
              icon: 'pi pi-fw pi-sitemap',
              to: '/admin/clubs/list'
            },
            {
              label: 'Affiliated Groups',
              icon: 'pi pi-fw pi-share-alt',
              to: '/admin/affiliated-groups/list'
            },
            {
              label: 'Renewals',
              icon: 'pi pi-fw pi-info-circle',
              to: '/admin/administration/renewals'
            }
          ]
        },
        {
          label: 'Profile',
          icon: 'pi pi-fw pi-id-card',
          items: [
            {
              label: 'Update',
              icon: 'pi pi-fw pi-refresh',
              to: '/admin/profile/update'
            },
            {
              label: 'Logout',
              icon: 'pi pi-fw pi-sign-out',
              command: (_event) => {
                this.onLogout();
              }
            }
          ]
        }
      ]
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('admin/logout');
      this.$router.push({name: 'adminLogin'});
    }
  }
}
</script>
