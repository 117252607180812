import axios from "axios";

export default {
    fetchClubs(_context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/data/clubs',
                method: 'GET'
            }).then(resp => {
                resolve(resp.data);
            }).catch(_error => {
                reject('Could not fetch clubs due to a technical error.');
            });
        });
    },
    fetchAffiliatedGroups(_context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/data/affiliated-groups',
                method: 'GET'
            }).then(resp => {
                resolve(resp.data);
            }).catch(_error => {
                reject('Could not fetch affiliated groups due to a technical error.');
            });
        });
    },
    fetchCountries(_context) {
        return new Promise((resolve, reject) => {
          axios({
              url: '/v1/data/countries',
              method: 'GET'
          }).then(resp => {
              resolve(resp.data);
          }).catch(_error => {
              reject('Could not fetch countries due to a technical error.');
          });
        });
    },
    fetchRenewalYears(_context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/data/renewal-years',
                method: 'GET',
            }).then(resp => {
                resolve(resp.data);
            }).catch(_error => {
                reject('Could not fetch the renewal years due to a technical error.');
            })
        })
    }
}
