import { createStore } from 'vuex';
import VuexPersistence from "vuex-persist";

import adminModule from './modules/admin/index.js';
import dataModule from './modules/data/index.js';
import membersModule from './modules/members/index.js';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = createStore({
    modules: {
        admin: adminModule,
        data: dataModule,
        members: membersModule,
    },
    plugins: [vuexLocal.plugin]
});

export default store;
