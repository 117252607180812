<template>
  <div class="wrapper">
    <div class="p-lg-8 p-md-10">
      <Panel header="Club Membership">
        <Message v-if="!this.vipMember" severity="success" :closable="false" :sticky="true">{{ discountMessage }}</Message>
        <p class="p-text-justify">Please select the AMACS clubs that you would like to be a member of from the list on
          the left. At least one club other than the pre-selected one needs be selected. If you are a member of any
          other group, please select it in the list of other groups.</p>
        <br/>
        <form @submit.prevent="onSubmit">
          <div class="p-grid">
            <div class="p-md-6">
              <Fieldset legend="AMACS Club Membership">
                <span v-for="club in clubs" :key="club['short_name']">
                  <div class="p-field-checkbox">
                    <Checkbox name="club"
                              :id="club['short_name']"
                              :value="club['short_name']"
                              :disabled="club.mandatory"
                              v-model="selectedClubs"/>
                    <label v-if="this.vipMember" :for="club['short_name']">{{ club.name }}</label>
                    <label v-else :for="club['short_name']">{{ club.name }} - €{{ parseFloat(club.fee).toFixed(2) }}</label>
                  </div>
                </span>
                <small v-if="v$.selectedClubs.$error" class="p-error">
                  Please select at least one club.
                </small>
                <div v-if="!this.vipMember">
                  <Divider/>
                  <div align="right">
                    <br/><span style="font-weight: bold;">Total:</span> €{{ total.toFixed(2) }}<span v-if="discount > 0"> (incl. €{{
                      discount
                    }} discount)</span>
                  </div>
                </div>
              </Fieldset>
            </div>
            <div class="p-md-6">
              <Fieldset legend="Group Membership">
                <span v-for="group in affiliatedGroups" :key="group['short_name']">
                  <div class="p-field-checkbox">
                    <Checkbox name="affiliatedGroup"
                              :id="group['short_name']"
                              :value="group['short_name']"
                              v-model="selectedAffiliatedGroups"/>
                    <label :for="group['short_name']">{{ group.name }}</label>
                  </div>
                </span>
              </Fieldset>
            </div>
          </div>
          <div align="right">
            <Button label="Cancel" class="p-button-secondary p-mr-2 p-mt-2" @click="onCancel"/>
            <Button type="submit" label="Proceed"/>
          </div>
        </form>
      </Panel>
    </div>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {minLength, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      vipMember: this.$store.getters['members/vip'],
      clubs: null,
      affiliatedGroups: null,
      selectedClubs: [],
      selectedAffiliatedGroups: [],
      total: 0,
      discount: 0,
      discountMessage: ""
    }
  },
  validations() {
    return {
      selectedClubs: {
        required,
        minLength: minLength(2),
        $autoDirty: true,
      }
    };
  },
  mounted() {
    this.$store.dispatch('data/fetchClubs').then(data => {
      this.clubs = data.filter(c => { return c.enabled; });
      this.clubs.forEach(c => {
        if (c.mandatory) {
          this.selectedClubs.push(c['short_name']);
        }
      });

      this.discountMessage = "A €10.00 discount will be given when joining all the clubs within AMACS."
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    this.$store.dispatch('data/fetchAffiliatedGroups').then(data => {
      this.affiliatedGroups = data.filter(c => { return c.enabled; });
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });
  },
  methods: {
    onCancel() {
      this.$store.commit('members/resetRenewalInformation');
      this.$router.push({name: 'memberMain'});
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const selectedMembership = {
        clubs: this.selectedClubs,
        affiliatedGroups: this.selectedAffiliatedGroups
      }
      this.$store.commit('members/addRenewalMembership', selectedMembership);

      this.$router.push({name: 'memberLicenceAndInsurance'})
    }
  },
  watch: {
    selectedClubs: {
      handler(selected) {
        let cost = 0;
        let discount = 0;
        selected.forEach(shortName => {
          const club = this.clubs.find(c => c['short_name'] === shortName);
          cost += parseFloat(club.fee);
        });

        if (cost === 55.00) {
          cost -= 10;
          discount = 10;
        }

        this.total = cost;
        this.discount = discount;
      },
      deep: true,
    },
  }
}
</script>
