<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Card>
      <template #content>
        <DataTable :value="clubs">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Clubs</h5>
              <span class="p-input-icon-left">
                <Button label="Add"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="onAddClub()"></Button>
              </span>
            </div>
          </template>
          <Column field="shortName" header="Short Name"></Column>
          <Column field="name" header="Full Name"></Column>
          <Column field="mandatory" header="Mandatory" headerStyle="min-width: 4rem; text-align: center"
                  bodyStyle="text-align: center; overflow: visible">
            <template #body="slotProps">
              <Checkbox id="mandatory"
                        v-model="slotProps.data.mandatory"
                        :binary="true"
                        @change="onChangeMandatoryStatus(slotProps.data.shortName, slotProps.data.mandatory)"/>
            </template>
          </Column>
          <Column field="fee" header="Fee">
            <template #body="slotProps">
              <InputNumber id="fee"
                           v-model="slotProps.data.fee"
                           showButtons
                           buttonLayout="horizontal"
                           :step="1.00"
                           :min="0"
                           decrementButtonClass="p-button-danger"
                           incrementButtonClass="p-button-success"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus"
                           mode="currency"
                           currency="EUR"
                           @input="onChangeFee($event, slotProps.data.shortName)"/>
            </template>
          </Column>
          <Column field="enabled" headerStyle="min-width: 4rem; text-align: center"
                  bodyStyle="text-align: right; overflow: visible">
            <template #body="slotProps">
              <Button :label="slotProps.data.enabled ? 'Disable' : 'Enable'"
                      :class="slotProps.data.enabled ? 'p-button-danger' : 'p-button-success'"
                      @click="setStatus(slotProps.data.shortName, !slotProps.data.enabled)"></Button>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <Toast/>
</template>

<script>
export default {
  data() {
    return {
      clubs: []
    }
  },
  mounted() {
    this.fetchClubs();
  },
  methods: {
    fetchClubs() {
      this.$store.dispatch('data/fetchClubs').then(data => {
        this.clubs = data.map(ag => {
          return {
            name: ag['name'],
            shortName: ag['short_name'].toUpperCase(),
            mandatory: ag['mandatory'],
            fee: parseInt(ag['fee']),
            enabled: ag['enabled']
          }
        });
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    onChangeMandatoryStatus(shortName, status) {
      const request = {
        shortName: shortName,
        status: status,
      }
      this.modifyClub('admin/setClubMandatoryStatus', request);
    },
    onChangeFee(event, shortName) {
      const request = {
        shortName: shortName,
        fee: event.value
      }
      this.modifyClub('admin/setClubFee', request)
    },
    setStatus(shortName, enabled) {
      const request = {
        shortName: shortName,
        status: enabled,
      }
      this.modifyClub('admin/setClubStatus', request)
    },
    modifyClub(action, request) {
      this.$store.dispatch(action, request).then(_data => {
        this.fetchClubs();
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    onAddClub() {
      this.$router.push({name: 'adminAddNewClub'});
    }
  }
}
</script>
