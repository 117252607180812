import axios from 'axios';

export default {
    login(context, req) {
        context.commit('resetStore');
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/login',
                method: 'POST',
                data: {
                    'username': req.username,
                    'password': req.password,
                }
            }).then(resp => {
                context.commit('setUsername', req.username);
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not log in as the request was malformed.');
                        break;
                    case 403:
                        reject('Administrator with given combination of username and password was not found.');
                        break;
                    default:
                        reject('Could not log in due to a technical error.');
                }
            });
        });
    },
    verify(context, auth_token) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/verify',
                method: 'POST',
                data: {
                    'username': context.getters.username,
                    'auth_token': auth_token,
                },
            }).then(resp => {
                context.commit('setToken', resp.data.token);
                context.commit('setRole', resp.data.group);
                context.commit('setFirstName', resp.data.first_name);
                context.commit('setLastName', resp.data.last_name);
                context.commit('setEmailAddress', resp.data.email);
                context.commit('setMobileNumber', resp.data.mobile);
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not verify token as the request was malformed.');
                        break;
                    case 403:
                        reject('Token not found or expired.');
                        break;
                    default:
                        reject('Could not verify token due to a technical error.');
                }
            });
        });
    },
    validateSession(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/auth/validate',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                reject(error);
            })
        });
    },
    logout(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/auth/logout',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 403:
                        reject('Session not found.');
                        break;
                    default:
                        reject('Could not verify token due to a technical error.');
                }
            }).finally(() => {
                context.commit('resetStore');
            });
        });
    },
    updateContactDetails(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/profile/contact-details',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'email': req.emailAddress,
                    'mobile': req.mobileNumber,
                }
            }).then(_resp => {
                resolve(req);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update the contact details as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to update this profile.');
                        break;
                    default:
                        reject('Could update the contact details due to a technical error.');
                }
            })
        });
    },
    updatePassword(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/profile/password',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'current_password': req.currentPassword,
                    'new_password': req.newPassword,
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update the password as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not to have sufficient permission update the password, or the old password was incorrect.');
                        break;
                    default:
                        reject('Could update the password due to a technical error.');
                }
            })
        });
    },
    fetchMembers(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/members',
                params: {
                    startMembershipNumber: req.startMembershipNumber,
                    limit: req.limit,
                },
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                const members = resp.data.map(data => {
                    return {
                        id: data['id'],
                        membershipNumber: data['membership_number'],
                        idCardNumber: data['id_number'],
                        title: data['title'],
                        firstName: data['first_name'],
                        lastName: data['last_name'],
                        fullName: `${data['first_name']} ${data['last_name']}`,
                        gender: data['gender'],
                        dateOfBirth: new Date(data['date_of_birth']),
                        placeOfBirth: data['place_of_birth'],
                        streetAddress: data['address']['street'],
                        town: data['address']['city'],
                        postCode: data['address']['post_code'],
                        mobileNumber: data['mobile_number'],
                        emailAddress: data['email'],
                        homePhone: data['home_phone'],
                        workPhone: data['office_phone'],
                        occupation: data['occupation'],
                        employer: data['employer'],
                        photo: data['photo'],
                        deceased: data['deceased'],
                        vip: data['vip'],
                        renewedThisYear: data['this_year'],
                        renewedNextYear: data['next_year'],
                        renewedPreviousYear: data['previous_year']
                    }
                });
                resolve(members);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update fetch the members as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to fetch the members.');
                        break;
                    default:
                        reject('Could not fetch the members due to a technical error.');
                }
            })
        })
    },
    fetchMemberPhoto(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/photo`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not fetch the member photo as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to fetch the member photo.');
                        break;
                    default:
                        reject('Could not fetch the member photo due to a technical error.');
                }
            })
        });
    },
    fetchLatestMemberLicences(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/licences/latest`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not fetch the latest member licences as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to fetch the latest member licences.');
                        break;
                    case 404:
                        reject('Could not find the latest member licences.');
                        break;
                    default:
                        reject('Could not fetch the member licences, memberships, and insurance due to a technical error.');
                }
            });
        });
    },
    fetchMembershipsAndLicences(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/memberships-and-licences`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                const data = resp.data;
                let mappedData = [];
                for (const [year, info] of Object.entries(data)) {
                    let licences = [];
                    for (const [id, value] of Object.entries(info['licences'])) {
                        licences.push({
                            id: id,
                            short_name: id,
                            obtained: value,
                        })
                    }

                    const insurance = [
                        {
                            id: 'third_party',
                            name: 'Third Party Liability',
                            bought: info['insurance']['third_party']
                        },
                        {
                            id: 'personal_accident',
                            name: 'Personal Accident',
                            bought: info['insurance']['personal_accident']
                        },
                        {
                            id: 'insured_elsewhere',
                            name: 'Insured Elsewhere',
                            bought: info['insurance']['insured_elsewhere'],
                            otherAgencyName: info['insurance']['insured_elsewhere_agency_name'],
                        }
                    ];

                    mappedData.push({
                        year: year,
                        licences: licences,
                        clubs: info['club_memberships'].map(cm => {
                            return {
                                id: cm['id'],
                                name: cm['name'],
                                shortName: cm['short_name'],
                                fee: cm['fee'],
                                mandatory: cm['mandatory'],
                                active: cm['enabled'],
                                member: cm['member']
                            };
                        }),
                        affiliatedGroups: info['affiliated_group_memberships'].map(agm => {
                            return {
                                id: agm['id'],
                                name: agm['name'],
                                shortName: agm['short_name'],
                                active: agm['enabled'],
                                member: agm['member']
                            };
                        }),
                        insurance: insurance
                    });
                }
                resolve(mappedData);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not fetch the member licences, memberships, and insurance as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to fetch the member licences, memberships, and insurance.');
                        break;
                    default:
                        reject('Could not fetch the member licences, memberships, and insurance due to a technical error.');
                }
            })
        })
    },
    updateMemberInsurance(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/insurance`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'third_party': req.thirdParty,
                    'personal_accident': req.personalAccident,
                    'insured_elsewhere': req.insuredElsewhere
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update member insurance as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to update member insurance.');
                        break;
                    default:
                        reject('Could not update member insurance due to a technical error.');
                }
            })
        })
    },
    updateMemberElsewhereInsuranceAgency(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/insurance/other-agency-name`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'other_agency_name': req.otherAgencyName
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update member insurance agency as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to update member insurance agency.');
                        break;
                    default:
                        reject('Could not update member insurance agency due to a technical error.');
                }
            })
        })
    },
    addMemberLicence(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/licences`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'short_name': req.licenceId
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not add member licence as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to add member licences.');
                        break;
                    default:
                        reject('Could not add member licence due to a technical error.');
                }
            })
        })
    },
    removeMemberLicence(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/licences`,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'short_name': req.licenceId
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not remove member licence as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to remove member licences.');
                        break;
                    default:
                        reject('Could not remove member licence due to a technical error.');
                }
            })
        })
    },
    addMemberToAffiliatedGroup(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/affiliated-groups`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'short_name': req.affiliatedGroupShortName
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not add member to affiliated group as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to add member to affiliated group.');
                        break;
                    default:
                        reject('Could not add member to affiliated group due to a technical error.');
                }
            })
        })
    },
    removeMemberFromAffiliatedGroup(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/affiliated-groups`,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'short_name': req.affiliatedGroupShortName
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not remove member from affiliated group as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to remove members from affiliated groups.');
                        break;
                    default:
                        reject('Could not remove member from affiliated group due to a technical error.');
                }
            })
        })
    },
    addMemberToClub(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/clubs`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'short_name': req.clubShortName
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not add member to club as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to add members to club.');
                        break;
                    default:
                        reject('Could not add member to club due to a technical error.');
                }
            })
        })
    },
    removeMemberFromClub(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/clubs`,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'short_name': req.clubShortName
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not remove member from club as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to remove members from clubs.');
                        break;
                    default:
                        reject('Could not remove member from club due to a technical error.');
                }
            })
        })
    },
    addNewMember(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/members',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'member': {
                        'title': req.personalDetails.title,
                        'first_name': req.personalDetails.firstName,
                        'last_name': req.personalDetails.lastName,
                        'id_number': req.personalDetails.idCardNumber,
                        'gender': req.personalDetails.gender,
                        'address': {
                            'street': req.personalDetails.streetAddress,
                            'city': req.personalDetails.town,
                            'post_code': req.personalDetails.postCode,
                            'country': 'Malta'
                        },
                        'home_phone': req.personalDetails.homePhone,
                        'office_phone': req.personalDetails.workPhone,
                        'mobile_number': req.personalDetails.mobileNumber,
                        'email': req.personalDetails.emailAddress,
                        'employer': req.personalDetails.employer,
                        'occupation': req.personalDetails.occupation,
                        'date_of_birth': req.personalDetails.dateOfBirth,
                        'place_of_birth': req.personalDetails.placeOfBirth,
                        'photo': req.personalDetails.photo
                    },
                    'clubs': req.clubs,
                    'affiliated_groups': req.affiliatedGroups,
                    'licences': req.licences,
                    'insurance': {
                        'third_party': req.insurance.thirdParty,
                        'personal_accident': req.insurance.personalAccident,
                        'insured_elsewhere': {
                            'has_insurance_elsewhere': req.insurance.insuredElsewhere.hasInsuranceElsewhere,
                            'other_insurance_agency_name': req.insurance.insuredElsewhere.insuranceElsewhereAgencyName
                        }
                    }
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not add a new member as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to add new members.');
                        break;
                    default:
                        reject('Could not add a new member due to a technical error.');
                }
            })
        });
    },
    renewMembership(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/renew`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': req.year,
                    'clubs': req.clubs,
                    'affiliated_groups': req.affiliatedGroups,
                    'licences': req.licences,
                    'insurance': {
                        'third_party': req.insurance.thirdParty,
                        'personal_accident': req.insurance.personalAccident,
                        'insured_elsewhere': {
                            'has_insurance_elsewhere': req.insurance.insuredElsewhere.hasInsuranceElsewhere,
                            'other_insurance_agency_name': req.insurance.insuredElsewhere.insuranceElsewhereAgencyName
                        }
                    }
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not renew membership as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to renew memberships.');
                        break;
                    default:
                        reject('Could not renew membership due to a technical error.');
                }
            })
        });
    },
    updateMemberDetails(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/admin/members/${req.membershipNumber}/`,
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${context.getters.token}`},
                data: {
                    'id_number': req.idCardNumber,
                    'title': req.title,
                    'first_name': req.firstName,
                    'last_name': req.lastName,
                    'gender': req.gender,
                    'date_of_birth': req.dateOfBirth.toISOString(),
                    'place_of_birth': req.placeOfBirth,
                    'deceased': req.deceased,
                    'vip': req.vip,
                    'address': {
                        'street': req.streetAddress,
                        'city': req.town,
                        'post_code': req.postCode,
                    },
                    'mobile_number': req.mobileNumber,
                    'email': req.emailAddress,
                    'home_phone': req.homePhone,
                    'office_phone': req.workPhone,
                    'occupation': req.occupation,
                    'employer': req.employer,
                    'photo': req.photo
                },
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update member details as request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to update member details.');
                        break;
                    default:
                        reject('Could not update member details due to a technical error.');
                }
            })
        });
    },
    renewAffiliatedGroupMemberMembership(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/members/affiliated-groups/insurance/renew',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'member_id': req.memberID > 0 ? req.memberID : null,
                    'membership_number': (req.membershipNumber !== '' && req.membershipNumber > 0) ? req.membershipNumber : null,
                    'year': req.year,
                    'insurance': {
                        'third_party': req.insurance.thirdParty,
                        'personal_accident': req.insurance.personalAccident,
                        'insured_elsewhere': {
                            'has_insurance_elsewhere': req.insurance.insuredElsewhere.hasInsuranceElsewhere,
                            'other_insurance_agency_name': req.insurance.insuredElsewhere.insuranceElsewhereAgencyName
                        }
                    }
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not renew insurance as request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to renew member insurance.');
                        break;
                    default:
                        reject('Could not renew member insurance due to a technical error.');
                }
            })
        });
    },
    fetchAdmins(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/administrators',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                const admins = resp.data.map(a => {
                    return {
                        username: a['username'],
                        firstName: a['first_name'],
                        lastName: a['last_name'],
                        email: a['email'],
                        mobile: a['mobile'],
                        group: a['group'].toUpperCase(),
                        enabled: a['enabled'],
                    }
                })
                resolve(admins);
            }).catch(error => {
                switch (error.response.status) {
                    case 403:
                        reject('You do not have sufficient permission to list administrators.');
                        break;
                    default:
                        reject('Could not list administrators due to a technical error.');
                }
            })
        });
    },
    addNewAdmin(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/administrators',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'username': req.username,
                    'password': req.password,
                    'group': req.group.toLowerCase(),
                    'first_name': req.firstName,
                    'last_name': req.lastName,
                    'email': req.emailAddress,
                    'mobile': req.mobileNumber,
                }
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not add administrator account as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to create a new administrator account.');
                        break;
                    default:
                        reject('Could not add administrator account due to a technical error.');
                }
            })
        });
    },
    setAdminAccountStatus(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/administrators/status',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: req,
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not set the status of the admin account as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to set the status of admin accounts.');
                        break;
                    default:
                        reject('Could not set the status of the admin account due to a technical error.');
                }
            })
        });
    },
    addNewClub(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/clubs',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'short_name': req.shortName.toLowerCase(),
                    'name': req.fullName,
                    'fee': req.fee.toString(),
                    'mandatory': req.mandatory,
                },
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not create the affiliated group as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to create an affiliated group.');
                        break;
                    default:
                        reject('Could not create the affiliated group due to a technical error.');
                }
            })
        });
    },
    setClubMandatoryStatus(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/clubs/mandatory-status',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'short_name': req.shortName.toLowerCase(),
                    'status': req.status,
                },
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not set the mandatory status of a club as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to set the mandatory status of a club.');
                        break;
                    default:
                        reject('Could not set the status of the club due to a technical error.');
                }
            })
        });
    },
    setClubFee(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/clubs/fee',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'short_name': req.shortName.toLowerCase(),
                    'fee': req.fee.toString(),
                },
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not set the fee of the club as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to set the fee of a club.');
                        break;
                    default:
                        reject('Could not set the fee of the club due to a technical error.');
                }
            })
        });
    },
    setClubStatus(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/clubs/status',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'short_name': req.shortName.toLowerCase(),
                    'status': req.status,
                },
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not set the status of the club as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to set the status of a club.');
                        break;
                    default:
                        reject('Could not set the status of the club due to a technical error.');
                }
            })
        });
    },
    addNewAffiliatedGroup(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/affiliated-groups',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'short_name': req.shortName.toLowerCase(),
                    'name': req.fullName,
                },
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not create the affiliated group as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to create an affiliated group.');
                        break;
                    default:
                        reject('Could not create the affiliated group due to a technical error.');
                }
            })
        });
    },
    setAffiliatedGroupStatus(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/affiliated-groups/status',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'short_name': req.shortName.toLowerCase(),
                    'status': req.status,
                },
            }).then(_resp => {
                resolve();
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not set the status of the affiliated group as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to set the status of an affiliated group.');
                        break;
                    default:
                        reject('Could not set the status of the affiliated group due to a technical error.');
                }
            })
        });
    },
    fetchInsuranceReport(context, req) {
        const formatDate = (d) => {
            const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return `${year}-${month}-${day}`;
        }
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/reports/insurance',
                params: {
                    year: req.year,
                    startDate: formatDate(req.startDate),
                    endDate: formatDate(req.endDate)
                },
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                const entries = resp.data.map(data => {
                    return {
                        certificateNumber: data['insurance_certificate_number'],
                        membershipNumber: data['membership_number'],
                        idCardNumber: data['id_number'],
                        firstName: data['first_name'],
                        lastName: data['last_name'],
                        address: data['address'],
                        city: data['city'],
                        tpl: data['tpl'],
                        pa: data['pa'],
                        stampDuty: data['stamp_duty'],
                        total: data['total']
                    }
                })
                resolve(entries);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not generate the insurance report as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to generate the insurance report.');
                        break;
                    default:
                        reject('Could not generate the insurance report due to a technical error.');
                }
            })
        });
    },
    fetchActiveMemberEmailList(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/reports/emails',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                }
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not generate the email list as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permission to generate the email list.');
                        break;
                    default:
                        reject('Could not generate the email list due to a technical error.');
                }
            })
        });
    },
    createRenewalYear(context, year) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/administration/renewal-year',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: {
                    'year': year,
                },
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not create the renewal year as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to create renewal year.');
                        break;
                    default:
                        reject('Could not create the renewal year due to a technical error.');
                }
            })
        })
    },
    setRenewalYearStatus(context, data) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/admin/administration/renewal-year/status',
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${context.getters.token}`
                },
                data: data,
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not set the status of the renewal year as the request was malformed.');
                        break;
                    case 403:
                        reject('You do not have sufficient permissions to set the status of the renewal year.');
                        break;
                    default:
                        reject('Could not set that status of the renewal year due to a technical error.');
                }
            })
        })
    }
}
