<template>
  <div class="wrapper-center-screen">
    <div class="p-lg-4 p-md-6">
      <Panel header="Login">
        <p class="p-text-justify">Please enter your mobile phone and your membership numbers to receive a 7-digit SMS
          code in order to confirm your identity. Your mobile phone number must be the same one that AMACS already has
          on file with your membership record.</p>
        <form @submit.prevent="onSubmit">
          <div class="p-fluid">
            <div class="p-field">
              <label for="mobile-number" class="p-sr-only">Mobile Number</label>
              <div class="p-inputgroup">
                <Dropdown id="countryCode"
                          v-model="mobileNumberCountryCode"
                          :options="countries"
                          optionLabel="code"
                          optionValue="code"
                          :filter="true"
                          style="max-width: 120px">
                  <template #option="slotProps">
                      <div class="country-item-value">
                        <img src="../../assets/images/flag_placeholder.png" :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                        <span>{{slotProps.option.code}}</span>
                      </div>
                  </template>
                </Dropdown>
                <InputText id="mobile-number"
                           type="number"
                           placeholder="Mobile Number"
                           v-model.trim="mobileNumber"
                           :class="{'p-invalid':v$.mobileNumber.$error}"/>
              </div>
              <small v-if="v$.mobileNumber.$error" class="p-error">
                Mobile number is invalid.
              </small>
            </div>
            <div class="p-field">
              <label for="membership-number" class="p-sr-only">Membership Number</label>
              <InputText id="membership-number"
                         type="number"
                         placeholder="Membership Number"
                         v-model.trim="membershipNumber"
                         :class="{'p-invalid':v$.membershipNumber.$error}"/>
              <small v-if="v$.membershipNumber.$error" class="p-error">
                Membership number is required and must be less than 5 digits.
              </small>
            </div>
          </div>
          <div align="right">
            <Button type="submit" label="Submit"/>
          </div>
        </form>
      </Panel>
    </div>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {maxLength, numeric, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      mobileNumber: '',
      countries: [],
      mobileNumberCountryCode: '+356',
      membershipNumber: '',
    };
  },
  mounted() {
    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    })
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        mobileNumber: `${this.mobileNumberCountryCode} ${this.mobileNumber}`,
        membershipNumber: this.membershipNumber,
      }

      this.$store.dispatch('members/login', request).then(() => {
        this.$router.replace({name: 'memberVerify'});
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  },
  validations() {
    const validPhoneNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }
    return {
      mobileNumber: {
        numeric,
        required,
        validPhoneNumber,
        $autoDirty: true
      },
      membershipNumber: {
        numeric,
        required,
        maxLength: maxLength(4),
        $autoDirty: true
      }
    }
  }
}
</script>
