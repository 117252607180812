export default {
    setMembershipNumber(state, membershipNumber) {
        state.membershipNumber = membershipNumber;
    },
    setMobileNumber(state, mobileNumber) {
        state.mobileNumber = mobileNumber;
    },
    setLoginTime(state, date) {
        state.loginTime = date;
    },
    setToken(state, token) {
        state.token = token;
    },
    setVIP(state, vip) {
        state.vip = vip;
    },
    setFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setLastName(state, lastName) {
        state.lastName = lastName;
    },
    setStreetAddress(state, streetAddress) {
        state.streetAddress = streetAddress;
    },
    setTown(state, town) {
        state.town = town;
    },
    setPostCode(state, postCode) {
        state.postCode = postCode;
    },
    resetStore(state) {
        state.membershipNumber = null;
        state.mobileNumber = null;
        state.loginTime = null;
        state.token = null;
        state.vip = null,
        state.firstName = null;
        state.lastName = null;
        state.streetAddress = null;
        state.town = null;
        state.postCode = null;
        state.renewalInformation = null;
    },
    resetRenewalInformation(state) {
        state.renewalInformation = null;
    },
    addRenewalYear(state, year) {
        if (state.renewalInformation === null) {
            state.renewalInformation = {};
        }
        state.renewalInformation['renewalYear'] = year;
    },
    addRenewalMembership(state, membership) {
        if (state.renewalInformation === null) {
            state.renewalInformation = {};
        }
        state.renewalInformation['clubs'] = membership.clubs;
        state.renewalInformation['affiliatedGroups'] = membership.affiliatedGroups;
    },
    addLicencesAndInsurance(state, info) {
        if (state.renewalInformation === null) {
            state.renewalInformation = {};
        }
        state.renewalInformation['licences'] = info.licences;
        state.renewalInformation['insurance'] = info.insurance;
    }
}
