<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Add New Affiliated Group">
      <form @submit.prevent="onSubmit">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-field p-col-12 p-md-12">
              <label for="shortName">Short name</label>
              <InputText id="shortName"
                         type="text"
                         v-model.trim="shortName"
                         @blur="v$.shortName.$touch()"
                         :class="{ 'p-invalid': v$.shortName.$error }"/>
              <small v-if="v$.shortName.$error" class="p-error">
                Short name is required and must be at least 3 characters.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <label for="fullName">Full Name</label>
              <InputText id="fullName"
                         type="text"
                         v-model.trim="fullName"
                         @blur="v$.fullName.$touch()"
                         :class="{ 'p-invalid': v$.fullName.$error }"/>
              <small v-if="v$.fullName.$error" class="p-error">
                Full name is required.
              </small>
            </div>
          </div>
        </div>
      </form>
      <div align="right">
        <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel()"/>
        <Button label="Submit" @click="onSubmit()"/>
      </div>
    </Panel>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {minLength, required} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      shortName: null,
      fullName: null,
    }
  },
  validations() {
    return {
      shortName: {
        required,
        minLength: minLength(3),
        $autoDirty: true
      },
      fullName: {
        required,
        $autoDirty: true
      }
    }
  },
  methods: {
    onCancel() {
      this.$router.push({name: 'adminListAffiliatedGroups'});
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        shortName: this.shortName,
        fullName: this.fullName,
      }

      this.$store.dispatch('admin/addNewAffiliatedGroup', request).then(_resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Affiliated Group created',
          detail: 'Affiliated group was added successfully.',
          life: 3000
        });
        setTimeout(() => {
          this.$router.push({name: 'adminListAffiliatedGroups'});
        }, 1500);
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      })
    }
  }
}
</script>
