export default {
    membershipNumber(state) {
        return state.membershipNumber;
    },
    isLoggedIn(state) {
        if (!state.loginTime) {
            return false;
        }
        const timeElapsedInMinutes = (Date.now() - state.loginTime) / 60000;
        return ((state.membershipNumber !== null) &&
            (state.mobileNumber !== null) &&
            (timeElapsedInMinutes < 20));
    },
    token(state) {
        return state.token;
    },
    vip(state) {
        return state.vip;
    },
    firstName(state) {
        return state.firstName;
    },
    lastName(state) {
        return state.lastName;
    },
    streetAddress(state) {
        return state.streetAddress;
    },
    town(state) {
        return state.town;
    },
    postCode(state) {
        return state.postCode;
    },
    renewalInformation(state) {
        return state.renewalInformation;
    },
    renewalYear(state) {
        return state.renewalInformation['renewalYear'];
    },
    boughtInsurance(state) {
        return state.renewalInformation['insurance']['thirdParty'];
    }
}
