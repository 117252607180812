import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state() {
        return {
            username: null,
            token: null,
            firstName: null,
            lastName: null,
            emailAddress: null,
            mobileNumber: null,
            role: null,
        };
    },
    actions,
    getters,
    mutations
};
