export default {
    resetStore(state) {
        state.username = null;
        state.token = null;
        state.firstName = null;
        state.lastName = null;
        state.emailAddress = null;
        state.mobileNumber = null;
        state.role = null;
    },
    setUsername(state, username) {
        state.username = username;
    },
    setToken(state, token) {
        state.token = token;
        state.lastTokenRefreshTime = Date.now();
    },
    setRole(state, role) {
        state.role = role;
    },
    setFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setLastName(state, lastName) {
        state.lastName = lastName;
    },
    setEmailAddress(state, emailAddress) {
        state.emailAddress = emailAddress;
    },
    setMobileNumber(state, mobileNumber) {
        state.mobileNumber = mobileNumber;
    }
}
