import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state() {
        return {
            mobileNumber: null,
            membershipNumber: null,
            loginTime: null,
            token: null,
            vip: null,
            firstName: null,
            lastName: null,
            streetAddress: null,
            town: null,
            postCode: null,
            renewalInformation: null,
        };
    },
    actions,
    getters,
    mutations
};
