<template>
  <div class="wrapper">
    <div class="p-lg-4 p-md-6">
      <Panel header="Update Licences">
        <p class="p-text-justify">Please select the police licences that you have obtained to date from the list.</p>
        <form @submit.prevent="onSubmit">
          <span v-for="licence in licences" :key="licence.id">
            <div class="p-field-checkbox">
              <Checkbox name="club"
                        :id="licence.id"
                        :value="licence.id"
                        v-model="selectedLicences"/>
              <label :for="licence.id">{{ licence.name }}</label>
            </div>
          </span>
          <div align="right">
            <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel"/>
            <Button type="submit" label="Update"/>
          </div>
        </form>
      </Panel>
    </div>
    <Toast/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      licences: [
        {id: "collector_a", name: "Collector Licence A"},
        {id: "collector_a_special", name: "Collector Licence A (Special)"},
        {id: "target_a", name: "Target Shooter Licence A"},
        {id: "target_a_special", name: "Target Shooter Licence A (Special)"},
        {id: "target_a_cb", name: "Target Shooter Licence A (Crossbow)"},
        {id: "rco", name: "Range Conducting Officer"},
        {id: "target_b_ts", name: "Target Shooter Licence B (Tactical Shotguns)"},
        {id: "target_b_ag", name: "Target Shooter Licence B (Air Guns)"},
        {id: "target_b_cp", name: "Target Shooter Licence B (Clay Pigeon)"},
        {id: "target_b_ml", name: "Target Shooter Licence B (Muzzle Loaders)"},
      ],
      selectedLicences: []
    };
  },
  mounted() {
    this.$store.dispatch('members/fetchLatestLicences').then(data => {
      for (const [id, value] of Object.entries(data)) {
        if (value) {
          this.selectedLicences.push(id);
        }
      }
    }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    onCancel() {
      this.$router.push({name: 'memberMain'});
    },
    onSubmit() {
      this.$store.dispatch('members/updateLatestLicences', this.selectedLicences).then(_resp => {
        this.$router.push({name: 'memberMain'});
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  },
  watch: {
    selectedLicences: {
      handler: function (newValue, oldValue) {
        const licenceAdded = newValue.filter(x => !oldValue.includes(x));
        if (licenceAdded.length === 0) {
          return;
        }

        let index = -1;
        switch (licenceAdded[0]) {
          case "collector_a":
            index = this.selectedLicences.indexOf("collector_a_special");
            break;
          case "collector_a_special":
            index = this.selectedLicences.indexOf("collector_a");
            break
          case "target_a":
            index = this.selectedLicences.indexOf("target_a_special");
            break;
          case "target_a_special":
            index = this.selectedLicences.indexOf("target_a");
            break;
        }
        if (index > -1) {
          this.selectedLicences.splice(index, 1);
        }
      },
      deep: true
    }
  }
}
</script>
