<template>
  <div class="wrapper-center-screen">
    <div>
      <Panel header="AMACS Member's Area">
        <template #icons>
          <button class="p-panel-header-icon p-link" @click="onLogout">
            <span class="pi pi-times"></span>
          </button>
        </template>
        <Fieldset>
          <template #legend>
            <label for="yearSelector" class="p-mr-2">Year:</label>
            <Dropdown id="yearSelector" v-model="selectedYear" :options="availableYears"/>
          </template>
          <div align="center">
            <Button type="button"
                    class="p-button-lg p-mr-2 p-mb-2"
                    style="width: 180px;"
                    :disabled="!renewalAvailableForSelectedYear"
                    @click="onRenewMembership">
              <div style="align-content: center;">
                <i class="pi pi-user-plus" style="font-size: 2em"></i><br/>
                <span class="p-ml-2 p-text-bold">Renew Membership</span>
              </div>
            </Button>
            <Button type="button"
                    class="p-button-lg p-mr-2 p-mb-2"
                    style="width: 180px;"
                    :disabled="!hasRenewedInSelectedYear"
                    @click="onPrintDocument('mc')">
              <div>
                <i class="pi pi-id-card" style="font-size: 2em"></i><br/>
                <span class="p-ml-2 p-text-bold">Print Membership Card</span>
              </div>
            </Button>
            <Button type="button"
                    class="p-button-lg p-mb-2"
                    style="width: 180px;"
                    :disabled="!hasInsuranceInSelectedYear"
                    @click="onPrintDocument('ic')">
              <div>
                <i class="pi pi-file" style="font-size: 2em"></i><br/>
                <span class="p-ml-2 p-text-bold">Print Insurance Certificate</span>
              </div>
            </Button>
          </div>
        </Fieldset>
        <div align="center">
          <Button type="button"
                  class="p-button-lg p-mr-2 p-mt-5 p-mb-3"
                  style="width: 180px;"
                  @click="onUpdatePersonalDetails">
            <div>
              <i class="pi pi-user" style="font-size: 2em"></i><br/>
              <span class="p-ml-2 p-text-bold">Update Personal Details</span>
            </div>
          </Button>
          <Button type="button"
                  class="p-button-lg p-mr-2 p-mb-3"
                  style="width: 180px;"
                  @click="onUpdateLicences">
            <div>
              <i class="pi pi-list" style="font-size: 2em"></i><br/>
              <span class="p-ml-2 p-text-bold">Update Obtained Licences</span>
            </div>
          </Button>
        </div>
      </Panel>
    </div>
  </div>
  <Toast/>
</template>

<script>
export default {
  data() {
    return {
      renewalYears: [],
      membershipYears: {},

      selectedYear: null
    };
  },
  computed: {
    availableYears() {
      const allYears = new Set();
      this.renewalYears.filter(renewalYear => renewalYear.enabled).forEach(renewalYear => allYears.add(renewalYear.year));
      Object.keys(this.membershipYears).forEach(year => allYears.add(year));

      return Array.from(allYears).sort().reverse();
    },
    renewalAvailableForSelectedYear() {
      const renewalYear = this.renewalYears.find(ry => ry.year === this.selectedYear)
      const hasRenewedInYear = this.membershipYears[this.selectedYear] !== undefined;
      return ((renewalYear !== undefined) && (renewalYear.enabled) && !hasRenewedInYear);
    },
    hasRenewedInSelectedYear() {
      return this.membershipYears[this.selectedYear] !== undefined;
    },
    hasInsuranceInSelectedYear() {
      const membershipStatusInYear = this.membershipYears[this.selectedYear];
      return ((membershipStatusInYear !== undefined) && (membershipStatusInYear['bought_insurance']));
    }
  },
  mounted() {
    this.fetchRenewalYears();
    this.fetchMembershipYears();
  },
  methods: {
    fetchRenewalYears() {
      this.$store.dispatch('data/fetchRenewalYears').then(data => {
        this.renewalYears = data;
        const latestYear = data.find(ry => ry.enabled);
        if (latestYear !== undefined) {
          this.selectedYear = latestYear.year;
        }
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    fetchMembershipYears() {
      this.$store.dispatch('members/getMembershipDetails').then(data => {
        this.membershipYears = data;
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    onLogout() {
      this.$store.commit('members/resetStore');
      this.$router.push({name: 'memberLogin'});
    },
    onRenewMembership() {
      this.$store.commit('members/addRenewalYear', this.selectedYear);
      this.$router.push({name: 'memberUpdateDetails'});
    },
    onPrintDocument(documentType) {
      const token = this.$store.getters['members/token'];
      const membershipNumber = this.$store.getters['members/membershipNumber'];
      window.open(`/v1/print/document?type=${documentType}&member=${membershipNumber}&year=${this.selectedYear}&token=${token}`)
    },
    onUpdatePersonalDetails() {
      this.$router.push({
        name: 'memberUpdateDetails',
        params: {
          source: 'updateDetails'
        }
      });
    },
    onUpdateLicences() {
      this.$router.push({name: 'memberUpdateLicences'});
    },
  }
}
</script>
