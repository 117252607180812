<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Insurance Report">
      <form @submit.prevent="onSubmit">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-field p-col-2 p-md-2">
              <label for="year">Year</label>
              <Dropdown id="year"
                        v-model="selectedYear"
                        :options="yearRangeForDropDown"
                        optionLabel="year"
                        optionValue="year"/>
            </div>
            <div class="p-field p-col-5 p-md-5">
              <label for="startDate">Start</label>
              <Calendar id="startDatePopup" class="form-element"
                        placeholder="dd/mm/yyyy"
                        dateFormat="dd/mm/yy"
                        :monthNavigator="true"
                        :yearNavigator="true"
                        :yearRange="yearRange"
                        :maxDate="new Date()"
                        v-model="startDate"
                        @blur="v$.startDate.$touch()"
                        :class="{ 'p-invalid': v$.startDate.$error }">
              </Calendar>
              <small v-if="v$.startDate.$error" class="p-error">
                Start date is required and should be in the past.
              </small>
            </div>
            <div class="p-field p-col-5 p-md-5">
              <label for="endDate">End</label>
              <Calendar id="endDatePopup" class="form-element"
                        placeholder="dd/mm/yyyy"
                        dateFormat="dd/mm/yy"
                        :monthNavigator="true"
                        :yearNavigator="true"
                        :yearRange="yearRange"
                        :minDate="startDate"
                        v-model="endDate"
                        @blur="v$.endDate.$touch()"
                        :class="{ 'p-invalid': v$.endDate.$error }">
              </Calendar>
              <small v-if="v$.endDate.$error" class="p-error">
                End date is required and should be after the start date.
              </small>
            </div>
          </div>
        </div>
        <div align="right">
          <Button label="Search" @click="onSubmit"/>
        </div>
      </form>
      <Divider v-if="this.result !== null"/>
      <DataTable v-if="this.result !== null"
                 :value="result"
                 :autoLayout="true"
                 dataKey="certificateNumber"
                 :paginator="true"
                 :rows="100"
                 ref="report">
        <template #header>
          <div style="text-align: right">
            <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
          </div>
        </template>
        <Column field="certificateNumber" header="Cert. #"></Column>
        <Column field="membershipNumber" header="Mem. #"></Column>
        <Column field="idCardNumber" header="ID Card No."></Column>
        <Column field="firstName" header="Name"></Column>
        <Column field="lastName" header="Surname"></Column>
        <Column field="address" header="House & Street"></Column>
        <Column field="city" header="Town"></Column>
        <Column field="tpl" header="TPL">
          <template #body="slotProps">
            €{{ parseFloat(slotProps.data.tpl).toFixed(2) }}
          </template>
        </Column>
        <Column field="pa" header="PA">
          <template #body="slotProps">
            €{{ parseFloat(slotProps.data.pa).toFixed(2) }}
          </template>
        </Column>
        <Column field="stampDuty" header="Stamp D.">
          <template #body="slotProps">
            €{{ parseFloat(slotProps.data.stampDuty).toFixed(2) }}
          </template>
        </Column>
        <Column field="total" header="Total">
          <template #body="slotProps">
            €{{ parseFloat(slotProps.data.total).toFixed(2) }}
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {numeric, required} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    const today = new Date();
    const years = [];
    for (let step = -1; step < 10; step++) {
      years.push({year: `${today.getFullYear() - step}`});
    }

    return {
      yearRangeForDropDown: years,
      yearRange: `${years[10].year}:${years[1].year}`,

      selectedYear: `${years[1].year}`,
      startDate: null,
      endDate: null,
      result: null
    }
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      this.result = null;

      const params = {
        year: this.selectedYear,
        startDate: this.startDate,
        endDate: this.endDate
      };
      this.$store.dispatch("admin/fetchInsuranceReport", params).then(data => {
        this.result = data;
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    exportCSV() {
      this.$refs.report.exportCSV();
    }
  },
  validations() {
    const validStartDate = (value) => {
      const today = new Date();
      return today > value;
    };

    const validEndDate = (value) => {
      if (this.startDate === null) return true;
      else return value > this.startDate;
    };

    return {
      selectedYear: {
        required,
        numeric,
        $autoDirty: true,
      },
      startDate: {
        required,
        validStartDate,
        $autoDirty: true
      },
      endDate: {
        required,
        validEndDate,
        $autoDirty: true
      }
    }
  }
}
</script>
