<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Update Profile">
      <div class="p-grid">
        <div class="p-md-6 p-sm-6">
          <Fieldset legend="Change Contact Details">
            <form @submit.prevent="onChangeContactDetails()">
              <div class="p-fluid">
                <div class="p-field">
                  <label for="mobileNumber">Mobile Number</label>
                  <div class="p-inputgroup">
                    <Dropdown id="countryCode"
                              v-model="mobileNumberCountryCode"
                              :options="countries"
                              optionLabel="code"
                              optionValue="code"
                              :filter="true"
                              style="max-width: 120px">
                      <template #option="slotProps">
                        <div class="country-item-value">
                          <img src="../../../assets/images/flag_placeholder.png"
                               :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                          <span>{{ slotProps.option.code }}</span>
                        </div>
                      </template>
                    </Dropdown>
                    <InputText id="mobile-number"
                               type="number"
                               v-model.trim="mobileNumber"
                               :class="{'p-invalid':v$.mobileNumber.$error}"/>
                  </div>
                  <small v-if="v$.mobileNumber.$error" class="p-error">
                    Mobile number is invalid.
                  </small>
                </div>
                <div class="p-field">
                  <label for="emailAddress">Email</label>
                  <InputText id="emailAddress"
                             type="email"
                             v-model.trim="emailAddress"
                             @blur="v$.emailAddress.$touch()"
                             :class="{ 'p-invalid': v$.emailAddress.$error }"/>
                  <small v-if="v$.emailAddress.$error" class="p-error">
                    A valid email address is required.
                  </small>
                </div>
              </div>
              <div style="text-align: right;">
                <Button type="submit" label="Update Contact Details"/>
              </div>
            </form>
          </Fieldset>
        </div>
        <div class="p-md-6 p-sm-6">
          <Fieldset legend="Change Password">
            <form @submit.prevent="onChangePassword()">
              <div class="p-fluid">
                <input id="username"
                       type="text"
                       v-model.trim="username"
                       autocomplete="on"
                       style="display: none;"/>
                <div class="p-field">
                  <label for="currentPassword">Current Password</label>
                  <InputText id="currentPassword"
                             type="password"
                             v-model.trim="currentPassword"
                             autocomplete="on"
                             :class="{'p-invalid':v$.currentPassword.$error}"/>
                  <small v-if="v$.currentPassword.$error" class="p-error">
                    Current password is required.
                  </small>
                </div>
                <div class="p-field">
                  <label for="newPassword">New Password</label>
                  <Password id="newPassword"
                            v-model.trim="newPassword"
                            @blur="v$.newPassword.$touch()"
                            autocomplete="new-password"
                            :class="{ 'p-invalid': v$.newPassword.$error }"/>
                  <small v-if="v$.newPassword.$error" class="p-error">
                    New password should be at least 6 characters long and different from current password.
                  </small>
                </div>
                <div class="p-field">
                  <label for="newPasswordConfirmation">Confirm New Password</label>
                  <InputText id="newPasswordConfirmation"
                             type="password"
                             v-model.trim="newPasswordConfirmation"
                             autocomplete="new-password"
                             :class="{'p-invalid':v$.newPasswordConfirmation.$error}"/>
                  <small v-if="v$.newPasswordConfirmation.$error" class="p-error">
                    Password confirmation must match new password.
                  </small>
                </div>
              </div>
              <div style="text-align: right;">
                <Button type="submit" label="Update Password"/>
              </div>
            </form>
          </Fieldset>
        </div>
      </div>
    </Panel>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {email, minLength, numeric, required, sameAs} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      countries: [],
      emailAddress: this.$store.getters['admin/emailAddress'],
      mobileNumberCountryCode: this.countryCodeOrDefault(this.$store.getters['admin/mobileNumber']),
      mobileNumber: this.numberOrDefault(this.$store.getters['admin/mobileNumber']),
      username: null,
      currentPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
    }
  },
  mounted() {
    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });
  },
  validations() {
    const mustBeDifferentFromCurrentPassword = (value) => value !== this.currentPassword;
    const validMobileNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    return {
      mobileNumber: {
        required,
        numeric,
        validMobileNumber,
        $autoDirty: true,
      },
      emailAddress: {
        required,
        email,
        $autoDirty: true,
      },
      currentPassword: {
        required,
        $autoDirty: true
      },
      newPassword: {
        required,
        minLength: minLength(6),
        mustBeDifferentFromCurrentPassword,
        $autoDirty: true
      },
      newPasswordConfirmation: {
        required,
        sameAsPassword: sameAs(this.newPassword),
        $autoDirty: true
      },
    }
  },
  methods: {
    countryCodeOrDefault(number) {
      if ((number === null) || (number === '')) {
        return '+356';
      }
      return number.split(' ')[0];
    },
    numberOrDefault(number) {
      if ((number === null) || (number === '')) {
        return '';
      }
      return number.split(' ')[1];
    },
    sendCompleteIfSet(prefix, number) {
      if ((number === null) || (number === '')) {
        return null;
      }
      return `${prefix} ${number}`;
    },
    onChangeContactDetails() {
      this.v$.mobileNumber.$touch();
      this.v$.emailAddress.$touch();
      if (this.v$.mobileNumber.$error || this.v$.emailAddress.$error) {
        return;
      }

      const request = {
        mobileNumber: this.sendCompleteIfSet(this.mobileNumberCountryCode, this.mobileNumber),
        emailAddress: this.emailAddress
      }

      this.$store.dispatch('admin/updateContactDetails', request).then(data => {
        this.$store.commit('admin/setEmailAddress', data.emailAddress);
        this.$store.commit('admin/setMobileNumber', data.mobileNumber);

        this.mobileNumberCountryCode = this.countryCodeOrDefault(data.mobileNumber);
        this.mobileNumber = this.numberOrDefault(data.mobileNumber);
        this.emailAddress = data.emailAddress;
        this.$toast.add({
          severity: 'success',
          summary: 'Contact Details Updated',
          detail: 'Your contact details were updated successfully.',
          life: 3000
        });
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
    ,
    onChangePassword() {
      this.v$.currentPassword.$touch();
      this.v$.newPassword.$touch();
      this.v$.newPasswordConfirmation.$touch();
      if (this.v$.currentPassword.$error || this.v$.newPassword.$error || this.v$.newPasswordConfirmation.$error) {
        return;
      }

      const request = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      }

      this.$store.dispatch('admin/updatePassword', request).then(_data => {
        this.currentPassword = null;
        this.v$.currentPassword.$reset();

        this.newPassword = null;
        this.v$.newPassword.$reset();

        this.newPasswordConfirmation = null;
        this.v$.newPasswordConfirmation.$reset();

        this.$toast.add({
          severity: 'success',
          summary: 'Password Updated',
          detail: 'Your password was updated successfully.',
          life: 3000
        });
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  }
}
</script>
