<template>
  <div class="wrapper">
    <div class="p-lg-8 p-md-10">
      <Panel header="Update Personal Details">
        <form @submit.prevent="onSubmit">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-lg-4 p-md-4">
              <MemberPhoto id="photo" v-model="photo"/>
              <small v-if="v$.photo.$error" class="p-error">
                Photo is required.
              </small>
            </div>
            <div class="p-lg-8 p-md-8">
              <div class="p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="membershipNumber">AMACS Membership Number</label>
                  <InputText id="membershipNumber"
                             disabled="true"
                             type="text"
                             v-model="membershipNumber"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="idCardNumber">ID Card Number</label>
                  <InputText id="idCardNumber"
                             type="text"
                             v-model.trim="idCardNumber"
                             @blur="padIdCardNumber"
                             :class="{ 'p-invalid': v$.idCardNumber.$error }"/>
                  <small v-if="v$.idCardNumber.$error" class="p-error">
                    ID card number must be made up of maximum 7 digits followed by A, P, M, G, Z, H, B or L.
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-2">
                  <label for="title">Title</label>
                  <InputText id="title"
                             type="text"
                             v-model.trim="title"
                             @blur="v$.title.$touch()"
                             :class="{ 'p-invalid': v$.title.$error }"/>
                  <small v-if="v$.title.$error" class="p-error">
                    Title is required.
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                  <label for="firstName">First Name</label>
                  <InputText id="firstName"
                             type="text"
                             v-model.trim="firstName"
                             @blur="v$.firstName.$touch()"
                             :class="{ 'p-invalid': v$.firstName.$error }"/>
                  <small v-if="v$.firstName.$error" class="p-error">
                    First name is required.
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                  <label for="lastName">LastName</label>
                  <InputText id="lastName"
                             type="text"
                             v-model.trim="lastName"
                             @blur="v$.lastName.$touch()"
                             :class="{ 'p-invalid': v$.lastName.$error }"/>
                  <small v-if="v$.lastName.$error" class="p-error">
                    Last name is required.
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="dateOfBirth">Date of Birth</label>
                  <Calendar id="popup" class="form-element"
                            placeholder="dd/mm/yyyy"
                            dateFormat="dd/mm/yy"
                            :monthNavigator="true"
                            :yearNavigator="true"
                            :yearRange="yearRange"
                            v-model="dateOfBirth"
                            @blur="v$.dateOfBirth.$touch()"
                            :class="{ 'p-invalid': v$.dateOfBirth.$error }">
                  </Calendar>
                  <small v-if="v$.dateOfBirth.$error" class="p-error">
                    Date of birth is required and must be entered as dd/mm/yyyy.
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="placeOfBirth">Place of Birth</label>
                  <InputText id="placeOfBirth"
                             type="text"
                             v-model.trim="placeOfBirth"
                             @blur="v$.placeOfBirth.$touch()"
                             :class="{ 'p-invalid': v$.placeOfBirth.$error }"/>
                  <small v-if="v$.placeOfBirth.$error" class="p-error">
                    Place of birth is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <label for="streetAddress">Street Address</label>
              <InputText id="streetAddress"
                         type="text"
                         v-model.trim="streetAddress"
                         @blur="v$.streetAddress.$touch()"
                         :class="{ 'p-invalid': v$.streetAddress.$error }"/>
              <small v-if="v$.streetAddress.$error" class="p-error">
                Street address is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="town">Town/Village</label>
              <InputText id="town"
                         type="text"
                         v-model.trim="town"
                         @blur="v$.town.$touch()"
                         :class="{ 'p-invalid': v$.town.$error }"/>
              <small v-if="v$.town.$error" class="p-error">
                Town/Village address is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="postCode">Post Code</label>
              <InputText id="postCode"
                         type="text"
                         v-model.trim="postCode"
                         @blur="v$.postCode.$touch()"
                         :class="{ 'p-invalid': v$.postCode.$error }"/>
              <small v-if="v$.postCode.$error" class="p-error">
                Post code is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mobileNumber">Mobile Number</label>
              <div class="p-inputgroup">
                <Dropdown id="countryCode"
                          v-model="mobileNumberCountryCode"
                          :options="countries"
                          optionLabel="code"
                          optionValue="code"
                          :filter="true"
                          style="max-width: 120px">
                  <template #option="slotProps">
                    <div class="country-item-value">
                      <img src="../../assets/images/flag_placeholder.png"
                           :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                      <span>{{ slotProps.option.code }}</span>
                    </div>
                  </template>
                </Dropdown>
                <InputText id="mobile-number"
                           type="number"
                           placeholder="Mobile Number"
                           v-model.trim="mobileNumber"
                           :class="{'p-invalid':v$.mobileNumber.$error}"/>
              </div>
              <small v-if="v$.mobileNumber.$error" class="p-error">
                Mobile number is invalid.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="emailAddress">Email</label>
              <InputText id="emailAddress"
                         type="email"
                         v-model.trim="emailAddress"
                         @blur="v$.emailAddress.$touch()"
                         :class="{ 'p-invalid': v$.emailAddress.$error }"/>
              <small v-if="v$.emailAddress.$error" class="p-error">
                A valid email address is required.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="homePhone">Home Phone</label>
              <div class="p-inputgroup">
                <Dropdown id="countryCode"
                          v-model="homePhoneCountryCode"
                          :options="countries"
                          optionLabel="code"
                          optionValue="code"
                          :filter="true"
                          style="max-width: 120px">
                  <template #option="slotProps">
                    <div class="country-item-value">
                      <img src="../../assets/images/flag_placeholder.png"
                           :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                      <span>{{ slotProps.option.code }}</span>
                    </div>
                  </template>
                </Dropdown>
                <InputText id="homePhone"
                           type="number"
                           v-model.trim="homePhone"
                           @blur="v$.homePhone.$touch()"
                           :class="{ 'p-invalid': v$.homePhone.$error }"/>
              </div>
              <small v-if="v$.homePhone.$error" class="p-error">
                Home phone is not valid.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="workPhone">Work Phone</label>
              <div class="p-inputgroup">
                <Dropdown id="countryCode"
                          v-model="workPhoneCountryCode"
                          :options="countries"
                          optionLabel="code"
                          optionValue="code"
                          :filter="true"
                          style="max-width: 120px">
                  <template #option="slotProps">
                    <div class="country-item-value">
                      <img src="../../assets/images/flag_placeholder.png"
                           :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                      <span>{{ slotProps.option.code }}</span>
                    </div>
                  </template>
                </Dropdown>
                <InputText id="workPhone"
                           type="number"
                           v-model.trim="workPhone"
                           @blur="v$.workPhone.$touch()"
                           :class="{ 'p-invalid': v$.workPhone.$error }"/>
              </div>
              <small v-if="v$.workPhone.$error" class="p-error">
                Work phone is not valid.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="occupation">Occupation</label>
              <InputText id="occupation" type="text" v-model.trim="occupation"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="employer">Employer</label>
              <InputText id="employer" type="text" v-model.trim="employer"/>
            </div>
          </div>
          <div align="right">
            <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel"/>
            <Button type="submit" label="Update"/>
          </div>
        </form>
      </Panel>
    </div>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {email, helpers, numeric, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: {
    source: {
      type: String,
    }
  },
  data() {
    const today = new Date();
    return {
      yearRange: `${today.getFullYear() - 100}:${today.getFullYear()}`,
      countries: [],

      membershipNumber: 0,
      idCardNumber: null,
      title: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      placeOfBirth: null,
      streetAddress: null,
      town: null,
      postCode: null,
      mobileNumber: null,
      mobileNumberCountryCode: null,
      emailAddress: null,
      homePhone: null,
      homePhoneCountryCode: null,
      workPhone: null,
      workPhoneCountryCode: null,
      occupation: null,
      employer: null,
      photo: null,
    };
  },
  validations() {
    const idCardNumber = (value) => /^[0-9]{3,7}[AaBbGgHhLlMmPpZz]/.test(value);
    const date = (value) => value instanceof Date || /\d{2}\/\d{2}\/\d{4}/.test(value);

    const validMobileNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    const validHomeNumber = (value) => {
      if (!helpers.req(value) && (value == null || value.length === 0)) return true;
      if (this.homePhoneCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    const validWorkNumber = (value) => {
      if (!helpers.req(value) && (value == null || value.length === 0)) return true;
      if (this.workPhoneCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    return {
      idCardNumber: {
        required,
        idCardNumber,
        $autoDirty: true,
      },
      title: {
        required,
        $autoDirty: true,
      },
      firstName: {
        required,
        $autoDirty: true,
      },
      lastName: {
        required,
        $autoDirty: true,
      },
      dateOfBirth: {
        required,
        date,
        $autoDirty: true,
      },
      placeOfBirth: {
        required,
        $autoDirty: true,
      },
      streetAddress: {
        required,
        $autoDirty: true,
      },
      town: {
        required,
        $autoDirty: true,
      },
      postCode: {
        required,
        $autoDirty: true,
      },
      mobileNumber: {
        required,
        numeric,
        validMobileNumber,
        $autoDirty: true,
      },
      emailAddress: {
        email,
        $autoDirty: true,
      },
      homePhone: {
        numeric,
        validHomeNumber,
        $autoDirty: true,
      },
      workPhone: {
        numeric,
        validWorkNumber,
        $autoDirty: true,
      },
      photo: {
        required,
      }
    };
  },
  methods: {
    shouldReturnToMain() {
      return this.source === 'updateDetails';
    },
    countryCodeOrDefault(number) {
      if ((number === null) || (number === '')) {
        return '+356';
      }
      return number.split(' ')[0];
    },
    numberOrDefault(number) {
      if ((number === null) || (number === '')) {
        return '';
      }
      return number.split(' ')[1];
    },
    sendCompleteIfSet(prefix, number) {
      if ((number === null) || (number === '')) {
        return null;
      }
      return `${prefix} ${number}`;
    },
    padIdCardNumber() {
      this.v$.idCardNumber.$touch();
      if (this.v$.idCardNumber.$error) {
        return;
      }
      this.idCardNumber = this.idCardNumber.padStart(8, '0');
    },
    onCancel() {
      this.$store.commit('members/resetRenewalInformation');
      this.$router.push({name: 'memberMain'});
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        idCardNumber: this.idCardNumber,
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        dateOfBirth: this.dateOfBirth,
        placeOfBirth: this.placeOfBirth,
        streetAddress: this.streetAddress,
        town: this.town,
        postCode: this.postCode,
        mobileNumber: this.sendCompleteIfSet(this.mobileNumberCountryCode, this.mobileNumber),
        emailAddress: this.emailAddress,
        homePhone: this.sendCompleteIfSet(this.homePhoneCountryCode, this.homePhone),
        workPhone: this.sendCompleteIfSet(this.workPhoneCountryCode, this.workPhone),
        occupation: this.occupation,
        employer: this.employer,
        photo: this.photo
      };

      this.$store.dispatch('members/updateMemberDetails', request).then(_resp => {
        this.$store.commit('members/setFirstName', this.firstName);
        this.$store.commit('members/setLastName', this.lastName);
        this.$store.commit('members/setStreetAddress', this.streetAddress);
        this.$store.commit('members/setTown', this.town);
        this.$store.commit('members/setPostCode', this.postCode);

        if (this.shouldReturnToMain()) {
          this.$router.push({name: 'memberMain'});
        } else {
          this.$router.push({name: 'memberMembership'});
        }
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  },
  mounted() {
    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    this.$store.dispatch('members/fetchMemberDetails').then(data => {
      this.membershipNumber = data['membership_number'];
      this.idCardNumber = data['id_number'];
      this.title = data['title'];
      this.firstName = data['first_name'];
      this.lastName = data['last_name'];
      this.dateOfBirth = new Date(data['date_of_birth']);
      this.placeOfBirth = data['place_of_birth'];
      this.streetAddress = data['address']['street'];
      this.town = data['address']['city'];
      this.postCode = data['address']['post_code'];
      this.mobileNumberCountryCode = this.countryCodeOrDefault(data['mobile_number']);
      this.mobileNumber = this.numberOrDefault(data['mobile_number']);
      this.emailAddress = data['email'];
      this.homePhoneCountryCode = this.countryCodeOrDefault(data['home_phone']);
      this.homePhone = this.numberOrDefault(data['home_phone']);
      this.workPhoneCountryCode = this.countryCodeOrDefault(data['office_phone']);
      this.workPhone = this.numberOrDefault(data['office_phone']);
      this.occupation = data['occupation'];
      this.employer = data['employer'];
      this.photo = data['photo'];

      this.$store.commit("members/setVIP", data['vip']);
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });
  }
}
</script>
