<template>
  <div class="wrapper">
    <div class="p-lg-4 p-md-6">
      <Panel header="Thank you">
        <p class="p-text-justify">Your payment was processed successfully and your membership was renewed. Thank you
          for being a valued member of our club. You can now print your:
        <ul>
          <li><a href="#" @click="printDocument('mc')">membership card</a></li>
          <li v-if="boughtInsurance"><a href="#" @click="printDocument('ic')">insurance certificate</a></li>
          <li><a href="#" @click="printDocument('rr')">receipt</a> which can be used to participate in the <a
              href="https://www.shopperlottery.com/" target="_blank">Shopper Lottery</a></li>
        </ul>
        Otherwise you can return to the
        <router-link :to="{name: 'memberMain'}">member's area</router-link>.</p>
      </Panel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boughtInsurance: this.$store.getters['members/boughtInsurance'],
    }
  },
  methods: {
    printDocument(documentType) {
      const token = this.$store.getters['members/token'];
      const membershipNumber = this.$store.getters['members/membershipNumber'];
      const year = this.$store.getters['members/renewalYear']
      window.open(`/v1/print/document?type=${documentType}&member=${membershipNumber}&year=${year}&token=${token}`)
    }
  }
}
</script>
