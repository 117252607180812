<template>
  <div class="p-grid p-jc-center">
    <div class="p-mt-2 p-mb-3">
      <label class="p-mr-2" for="year">Renewal year:</label>
      <Dropdown id="year"
                v-model="selectedYear"
                :options="years"
                optionLabel="name"
                optionValue="code"/>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-md-6">
      <Fieldset legend="Clubs">
        <span v-for="club in clubs" :key="club.id">
          <div class="p-field-checkbox">
            <Checkbox name="club"
                      :id="club['short_name']"
                      :value="club['short_name']"
                      :disabled="club.mandatory"
                      v-model="selectedClubs"/>
            <label v-if="member.vip" :for="club.id">{{ club.name }}</label>
            <label v-else :for="club.id">{{ club.name }} - €{{ parseFloat(club.fee).toFixed(2) }}</label>
          </div>
        </span>
        <small v-if="v$.selectedClubs.$error" class="p-error">
          Please select at least one club.
        </small>
      </Fieldset>
    </div>
    <div class="p-md-6">
      <Fieldset legend="Licences">
        <span v-for="licence in licences" :key="licence.id">
          <div class="p-field-checkbox">
            <Checkbox name="club"
                      :id="licence.id"
                      :value="licence.id"
                      v-model="selectedLicences"/>
            <label :for="licence.id">{{ licence.name }}</label>
          </div>
        </span>
      </Fieldset>
    </div>
    <div class="p-md-6">
      <Fieldset legend="Affiliated Groups">
        <span v-for="group in affiliatedGroups" :key="group.id">
          <div class="p-field-checkbox">
            <Checkbox name="affiliatedGroup"
                      :id="group['short_name']"
                      :value="group['short_name']"
                      v-model="selectedAffiliatedGroups"/>
            <label :for="group.id">{{ group.name }}</label>
          </div>
        </span>
      </Fieldset>
    </div>
    <div class="p-md-6">
      <Fieldset legend="Insurance">
        <div class="p-field-checkbox">
          <Checkbox name="thirdPartyInsurance"
                    id="thirdPartyInsurance"
                    :binary="true"
                    :disabled="insuredElsewhere"
                    v-model="thirdPartyInsurance"/>
          <label for="thirdPartyInsurance">Third Party Liability - €13.00</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox name="personalAccidentInsurance"
                    id="personalAccidentInsurance"
                    :binary="true"
                    :disabled="insuredElsewhere"
                    v-model="personalAccidentInsurance"/>
          <label for="personalAccidentInsurance">Personal Accident - €5.00</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox name="insuredElsewhere"
                    id="insuredElsewhere"
                    :binary="true"
                    v-model="insuredElsewhere"/>
          <label for="insuredElsewhere">Insured Elsewhere</label>
          <InputText id="insuredElsewhereName"
                     :disabled="!insuredElsewhere"
                     type="text"
                     class="p-ml-2"
                     v-model.trim="insuredElsewhereName"/>
        </div>
        <small v-if="insuranceRequiredButNotSelected" class="p-error">
          Insurance is required for target shooting licence holders.
        </small>
        <small v-if="insuredElsewhere && insuredElsewhereName === ''" class="p-error">
          Please specify the name of the insurance agency.
        </small>
      </Fieldset>
    </div>
  </div>
  <Divider/>
  <div align="right" class="p-pt-2">
    <span style="font-weight: bold">Total Cost:</span> €{{ totalCost.toFixed(2)}} <span v-if="this.discount > 0">(including €{{ this.discount.toFixed(2) }} discount)</span><br/><br/>
    <Button label="Renew" @click="onRenew"/>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {minLength, required} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'AdminUpdateMemberDetails',
  props: {
    member: {
      type: Object,
      default: null
    },
  },
  emits: ['error', 'infoMessage', 'closeMe'],
  data() {
    const today = new Date();
    const years = [];
    if (!this.member.renewedPreviousYear) {
      years.push({name: `${today.getFullYear() - 1}`, code: `${today.getFullYear() - 1}`})
    }
    if (!this.member.renewedThisYear) {
      years.push({name: `${today.getFullYear()}`, code: `${today.getFullYear()}`})
    }
    if (!this.member.renewedNextYear) {
      years.push({name: `${today.getFullYear() + 1}`, code: `${today.getFullYear() + 1}`})
    }

    return {
      years: years,

      clubs: null,
      affiliatedGroups: null,
      licences: [
        {id: "collector_a", name: "Collector Licence A"},
        {id: "collector_a_special", name: "Collector Licence A (Special)"},
        {id: "target_a", name: "Target Shooter Licence A"},
        {id: "target_a_special", name: "Target Shooter Licence A (Special)"},
        {id: "target_a_cb", name: "Target Shooter Licence A (Crossbow)"},
        {id: "rco", name: "Range Conducting Officer"},
        {id: "target_b_ts", name: "Target Shooter Licence B (Tactical Shotguns)"},
        {id: "target_b_ag", name: "Target Shooter Licence B (Air Guns)"},
        {id: "target_b_cp", name: "Target Shooter Licence B (Clay Pigeon)"},
        {id: "target_b_ml", name: "Target Shooter Licence B (Muzzle Loaders)"},
      ],

      selectedYear: (years.length > 0) ? years[0].code : '',

      selectedClubs: [],

      selectedAffiliatedGroups: [],

      selectedLicences: [],

      insuredElsewhere: false,
      insuredElsewhereName: '',
      thirdPartyInsurance: true,
      personalAccidentInsurance: false,
    };
  },
  mounted() {
    this.$store.dispatch('data/fetchClubs').then(data => {
      this.clubs = data.filter(c => {
        return c.enabled;
      });
      this.clubs.forEach(c => {
        if (c.mandatory) {
          this.selectedClubs.push(c['short_name']);
        }
      });
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    this.$store.dispatch('data/fetchAffiliatedGroups').then(data => {
      this.affiliatedGroups = data.filter(c => {
        return c.enabled;
      });
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    const req = {
      membershipNumber: this.member.membershipNumber
    };

    this.$store.dispatch('admin/fetchLatestMemberLicences', req).then(data => {
      for (const [id, value] of Object.entries(data)) {
        if (value) {
          this.selectedLicences.push(id);
        }
      }
    }).catch(error => {
      console.log(error);
    });
  },
  computed: {
    insuranceRequiredButNotSelected() {
      const hasTargetShooterLicence = this.selectedLicences.filter(l => l.startsWith("target"));
      return (hasTargetShooterLicence.length > 0) && !(this.thirdPartyInsurance || this.insuredElsewhere);
    },
    totalCost() {
      if (this.clubs === null) {
        return 0;
      }

      let cost = 0;
      if (!this.member.vip) {
        this.clubs.forEach(club => cost += this.selectedClubs.includes(club.short_name) ? parseFloat(club.fee) : 0);
      }
      if (this.thirdPartyInsurance) {
        cost += 13;
      }
      if (this.personalAccidentInsurance) {
        cost += 5;
      }

      return (cost - this.discount);
    },
    discount() {
      if (this.allClubsSelected()) {
        return 10;
      }
      return 0;
    }
  },
  watch: {
    selectedLicences: {
      handler: function (newValue, oldValue) {
        const licenceAdded = newValue.filter(x => !oldValue.includes(x));
        if (licenceAdded.length === 0) {
          return;
        }

        let index = -1;
        switch (licenceAdded[0]) {
          case "collector_a":
            index = this.selectedLicences.indexOf("collector_a_special");
            break;
          case "collector_a_special":
            index = this.selectedLicences.indexOf("collector_a");
            break
          case "target_a":
            index = this.selectedLicences.indexOf("target_a_special");
            break;
          case "target_a_special":
            index = this.selectedLicences.indexOf("target_a");
            break;
        }
        if (index > -1) {
          this.selectedLicences.splice(index, 1);
        }
      },
      deep: true
    },
    insuredElsewhere: function (selected) {
      if (selected) {
        this.thirdPartyInsurance = false;
        this.personalAccidentInsurance = false;
      } else {
        this.thirdPartyInsurance = true;
      }
    }
  },
  validations() {
    return {
      selectedClubs: {
        required,
        minLength: minLength(2),
        $autoDirty: true,
      }
    };
  },
  methods: {
    allClubsSelected() {
      if (this.clubs === null) {
        return false;
      }
      return this.clubs.reduce((prev, curr) => prev && this.selectedClubs.includes(curr.short_name), true);
    },
    onRenew() {
      this.v$.$touch();
      if (this.v$.$error || this.insuranceRequiredButNotSelected || (this.insuredElsewhere && this.insuredElsewhereName === '')) {
        return;
      }

      const req = {
        membershipNumber: this.member.membershipNumber,
        year: this.selectedYear,
        clubs: this.selectedClubs,
        affiliatedGroups: this.selectedAffiliatedGroups,
        licences: this.selectedLicences,
        insurance: {
          thirdParty: this.thirdPartyInsurance,
          personalAccident: this.personalAccidentInsurance,
          insuredElsewhere: {
            hasInsuranceElsewhere: this.insuredElsewhere,
            insuranceElsewhereAgencyName: this.insuredElsewhereName,
          }
        }
      }
      this.$store.dispatch('admin/renewMembership', req).then(_resp => {
        this.$emit('infoMessage', {
          title: 'Renewal successful',
          message: 'Membership was renewed successfully.'
        })
      }).catch(error => {
        this.$emit('error', error);
      });
      this.$emit('closeMe');
    }
  }
}
</script>
