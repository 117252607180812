<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Card>
      <template #content>
        <DataTable :value="displayMembers"
                   :autoLayout="true"
                   selectionMode="single"
                   dataKey="membershipNumber"
                   :paginator="true"
                   :rows="100"
                   v-model:filters="tableFilters"
                   filterDisplay="menu"
                   ref="report">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Members</h5>
              <div style="text-align: right">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filter" placeholder="Search"/>
                </span>
                <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" class="p-ml-2"/>
              </div>
            </div>
          </template>
          <Column field="fullName" header="Name" :sortable="true"></Column>
          <Column field="idCardNumber" header="ID Card No." :sortable="true"></Column>
          <Column field="membershipNumber" header="Memb. #" :sortable="true"></Column>
          <Column field="renewedPreviousYear" dataType="boolean" :header="previousYear" headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{data}">
              <i v-if="data.renewedPreviousYear" class="pi pi-check-circle" style="color: green"/>
              <i v-else class="pi pi-times-circle" style="color: red"/>
            </template>
            <template #filter="{filterModel}">
              <TriStateCheckbox v-model="filterModel.value"/>
            </template>
          </Column>
          <Column field="renewedThisYear" dataType="boolean" :header="thisYear" headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{data}">
              <i v-if="data.renewedThisYear" class="pi pi-check-circle" style="color: green"/>
              <i v-else class="pi pi-times-circle" style="color: red"/>
            </template>
            <template #filter="{filterModel}">
              <TriStateCheckbox v-model="filterModel.value"/>
            </template>
          </Column>
          <Column field="renewedNextYear" dataType="boolean" :header="nextYear" headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{data}">
              <i v-if="data.renewedNextYear" class="pi pi-check-circle" style="color: green"/>
              <i v-else class="pi pi-times-circle" style="color: red"/>
            </template>
            <template #filter="{filterModel}">
              <TriStateCheckbox v-model="filterModel.value"/>
            </template>
          </Column>
          <Column headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: right; overflow: visible">
            <template #body="member">
              <Button class="p-mr-2"
                      type="button"
                      icon="pi pi-file"
                      :disabled="!member.data.renewedThisYear"
                      @click="onPrintInsurance(member.data.id, member.data.membershipNumber)"/>
              <Button type="button"
                      icon="pi pi-plus"
                      :disabled="member.data.renewedThisYear"
                      @click="onRenewInsurance(member.data.id, member.data.membershipNumber)"/>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <Dialog v-model:header="renewInsuranceDialogHeader"
          v-model:visible="displayRenewInsuranceDialog"
          :closeOnEscape="true"
          :modal="true"
          :maximizable="false"
          :draggable="false">
    <RenewInsurance :member="selectedMember"
                    @infoMessage="onInfoMessage"
                    @error="onDialogError"
                    @closeMe="onCloseRenewInsuranceDialog"/>
  </Dialog>
  <Toast/>
</template>

<script>
import RenewInsurance from "@/components/admin/affiliated-group-admin/RenewInsurance.vue";
import {FilterMatchMode} from 'primevue/api';

export default {
  components: {RenewInsurance},
  data() {
    const today = new Date();
    return {
      thisYear: `${today.getFullYear()}`,
      previousYear: `${today.getFullYear() - 1}`,
      nextYear: `${today.getFullYear() + 1}`,
      tableFilters: {
        'renewedPreviousYear': {value: null, matchMode: FilterMatchMode.EQUALS},
        'renewedThisYear': {value: null, matchMode: FilterMatchMode.EQUALS},
        'renewedNextYear': {value: null, matchMode: FilterMatchMode.EQUALS}
      },
      members: [],
      displayMembers: [],
      filter: "",
      displayRenewInsuranceDialog: false,
      renewInsuranceDialogHeader: "",
      selectedMember: null,
    }
  },
  mounted() {
    this.fetchMembers();
  },
  methods: {
    fetchMembers() {
      const params = {
        startMembershipNumber: 0,
        limit: 0
      };

      this.$store.dispatch('admin/fetchMembers', params).then(data => {
        this.members = data;
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    mapMemberForDisplay(m) {
      return {
        id: m.id,
        membershipNumber: m.membershipNumber > 0 ? m.membershipNumber : '',
        idCardNumber: m.idCardNumber,
        mobileNumber: m.mobileNumber,
        fullName: m.fullName,
        emailAddress: m.emailAddress,
        renewedPreviousYear: m.renewedPreviousYear,
        renewedThisYear: m.renewedThisYear,
        renewedNextYear: m.renewedNextYear
      }
    },
    onInfoMessage(message) {
      this.$toast.add({
        severity: 'success',
        summary: message.title,
        detail: message.message,
        life: 3000
      });
    },
    onDialogError(error) {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    },
    onRenewInsurance(id, membershipNumber) {
      if (id > 0) {
        this.selectedMember = this.members.find(m => m.id === id);
      } else {
        this.selectedMember = this.members.find(m => m.membershipNumber === membershipNumber);
      }
      this.renewInsuranceDialogHeader = `Renew insurance for ${this.selectedMember.firstName} ${this.selectedMember.lastName}`;
      this.displayRenewInsuranceDialog = true;
    },
    onCloseRenewInsuranceDialog() {
      this.displayRenewInsuranceDialog = false;
    },
    onPrintInsurance(id, membershipNumber) {
      const token = this.$store.getters['admin/token'];
      const memberIdentification = id > 0 ? `memberid=${id}` : `member=${membershipNumber}`;
      window.open(`/v1/print/member-document?type=ic&${memberIdentification}&year=${this.thisYear}&token=${token}`)
    },
    exportCSV() {
      this.$refs.report.exportCSV();
    }
  },
  watch: {
    members: function (newData, _) {
      this.displayMembers = newData.map(m => this.mapMemberForDisplay(m));
    },
    filter: function (newFilter, _) {
      if (newFilter === "") {
        this.displayMembers = this.members.map(m => this.mapMemberForDisplay(m));
        return;
      }

      const searchString = newFilter.toLowerCase();
      // If it's numeric only, we match on mobile, membership number, and ID card number.
      if (/^\d+$/.test(newFilter)) {
        this.displayMembers = this.members.filter(m =>
            m.membershipNumber.toString().includes(searchString) ||
            m.idCardNumber.toString().includes(searchString) ||
            m.mobileNumber.toString().includes(searchString)
        );
        return;
      }

      // If it's text, we check the full name, id card number, and email.
      this.displayMembers = this.members.filter(m =>
          m.fullName.toLowerCase().includes(searchString) ||
          (m.idCardNumber !== undefined && m.idCardNumber !== null && m.idCardNumber.toString().toLowerCase().includes(searchString)) ||
          (m.emailAddress !== undefined && m.emailAddress !== null && m.emailAddress.toLowerCase().includes(searchString))
      );
    }
  }
}
</script>
