<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Add New Member">
      <div class="p-grid">
        <div class="p-md-12">
          <Fieldset legend="Personal Details">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-lg-4 p-md-4">
                <MemberPhoto id="photo" v-model="photo"/>
                <small v-if="v$.photo.$error" class="p-error">
                  Photo is required.
                </small>
              </div>
              <div class="p-lg-8 p-md-8">
                <div class="p-grid">
                  <div class="p-field p-col-12 p-md-2">
                    <label for="title">Title</label>
                    <InputText id="title"
                               type="text"
                               v-model.trim="title"
                               @blur="v$.title.$touch()"
                               :class="{ 'p-invalid': v$.title.$error }"/>
                    <small v-if="v$.title.$error" class="p-error">
                      Title is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-5">
                    <label for="firstName">First Name</label>
                    <InputText id="firstName"
                               type="text"
                               v-model.trim="firstName"
                               @blur="v$.firstName.$touch()"
                               :class="{ 'p-invalid': v$.firstName.$error }"/>
                    <small v-if="v$.firstName.$error" class="p-error">
                      First name is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-5">
                    <label for="lastName">LastName</label>
                    <InputText id="lastName"
                               type="text"
                               v-model.trim="lastName"
                               @blur="v$.lastName.$touch()"
                               :class="{ 'p-invalid': v$.lastName.$error }"/>
                    <small v-if="v$.lastName.$error" class="p-error">
                      Last name is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-6">
                    <label for="dateOfBirth">Date of Birth</label>
                    <Calendar id="popup" class="form-element"
                              placeholder="dd/mm/yyyy"
                              dateFormat="dd/mm/yy"
                              :monthNavigator="true"
                              :yearNavigator="true"
                              :yearRange="yearRange"
                              v-model="dateOfBirth"
                              @blur="v$.dateOfBirth.$touch()"
                              :class="{ 'p-invalid': v$.dateOfBirth.$error }">
                    </Calendar>
                    <small v-if="v$.dateOfBirth.$error" class="p-error">
                      Date of birth is required and must be entered as dd/mm/yyyy.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-6">
                    <label for="placeOfBirth">Place of Birth</label>
                    <InputText id="placeOfBirth"
                               type="text"
                               v-model.trim="placeOfBirth"
                               @blur="v$.placeOfBirth.$touch()"
                               :class="{ 'p-invalid': v$.placeOfBirth.$error }"/>
                    <small v-if="v$.placeOfBirth.$error" class="p-error">
                      Place of birth is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-6">
                    <label for="idCardNumber">ID Card Number</label>
                    <InputText id="idCardNumber"
                               type="text"
                               v-model.trim="idCardNumber"
                               @blur="padIdCardNumber"
                               :class="{ 'p-invalid': v$.idCardNumber.$error }"/>
                    <small v-if="v$.idCardNumber.$error" class="p-error">
                      ID card number must be made up of maximum 7 digits followed by A, P, M, G, Z, H, B or L.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-6">
                    <label for="gender">Gender</label>
                    <Dropdown id="gender"
                              v-model="gender"
                              :options="availableGenders"
                              optionLabel="name"
                              optionValue="code"
                              placeholder="Select a Gender"/>
                    <small v-if="v$.gender.$error" class="p-error">
                      Gender is required
                    </small>
                  </div>
                </div>
              </div>
              <div class="p-field p-col-12 p-md-12">
                <label for="streetAddress">Street Address</label>
                <InputText id="streetAddress"
                           type="text"
                           v-model.trim="streetAddress"
                           @blur="v$.streetAddress.$touch()"
                           :class="{ 'p-invalid': v$.streetAddress.$error }"/>
                <small v-if="v$.streetAddress.$error" class="p-error">
                  Street address is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="town">Town/Village</label>
                <InputText id="town"
                           type="text"
                           v-model.trim="town"
                           @blur="v$.town.$touch()"
                           :class="{ 'p-invalid': v$.town.$error }"/>
                <small v-if="v$.town.$error" class="p-error">
                  Town/Village address is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="postCode">Post Code</label>
                <InputText id="postCode"
                           type="text"
                           v-model.trim="postCode"
                           @blur="v$.postCode.$touch()"
                           :class="{ 'p-invalid': v$.postCode.$error }"/>
                <small v-if="v$.postCode.$error" class="p-error">
                  Post code is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="mobileNumber">Mobile Number</label>
                <div class="p-inputgroup">
                  <Dropdown id="countryCode"
                            v-model="mobileNumberCountryCode"
                            :options="countries"
                            optionLabel="code"
                            optionValue="code"
                            :filter="true"
                            style="max-width: 120px">
                    <template #option="slotProps">
                      <div class="country-item-value">
                        <img src="../../../assets/images/flag_placeholder.png"
                             :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                        <span>{{ slotProps.option.code }}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <InputText id="mobile-number"
                             type="number"
                             v-model.trim="mobileNumber"
                             :class="{'p-invalid':v$.mobileNumber.$error}"/>
                </div>
                <small v-if="v$.mobileNumber.$error" class="p-error">
                  Mobile number is invalid.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="emailAddress">Email</label>
                <InputText id="emailAddress"
                           type="email"
                           v-model.trim="emailAddress"
                           @blur="v$.emailAddress.$touch()"
                           :class="{ 'p-invalid': v$.emailAddress.$error }"/>
                <small v-if="v$.emailAddress.$error" class="p-error">
                  A valid email address is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="homePhone">Home Phone</label>
                <div class="p-inputgroup">
                  <Dropdown id="countryCode"
                            v-model="homePhoneCountryCode"
                            :options="countries"
                            optionLabel="code"
                            optionValue="code"
                            :filter="true"
                            style="max-width: 120px">
                    <template #option="slotProps">
                      <div class="country-item-value">
                        <img src="../../../assets/images/flag_placeholder.png"
                             :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                        <span>{{ slotProps.option.code }}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <InputText id="homePhone"
                             type="number"
                             v-model.trim="homePhone"
                             @blur="v$.homePhone.$touch()"
                             :class="{ 'p-invalid': v$.homePhone.$error }"/>
                </div>
                <small v-if="v$.homePhone.$error" class="p-error">
                  Home phone is not valid.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="workPhone">Work Phone</label>
                <div class="p-inputgroup">
                  <Dropdown id="countryCode"
                            v-model="workPhoneCountryCode"
                            :options="countries"
                            optionLabel="code"
                            optionValue="code"
                            :filter="true"
                            style="max-width: 120px">
                    <template #option="slotProps">
                      <div class="country-item-value">
                        <img src="../../../assets/images/flag_placeholder.png"
                             :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                        <span>{{ slotProps.option.code }}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <InputText id="workPhone"
                             type="number"
                             v-model.trim="workPhone"
                             @blur="v$.workPhone.$touch()"
                             :class="{ 'p-invalid': v$.workPhone.$error }"/>
                </div>
                <small v-if="v$.workPhone.$error" class="p-error">
                  Work phone is not valid.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="occupation">Occupation</label>
                <InputText id="occupation" type="text" v-model.trim="occupation"/>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="employer">Employer</label>
                <InputText id="employer" type="text" v-model.trim="employer"/>
              </div>
            </div>
          </Fieldset>
        </div>
        <div class="p-md-6">
          <Fieldset legend="Clubs">
            <span v-for="club in clubs" :key="club['short_name']">
                  <div class="p-field-checkbox">
                    <Checkbox name="club"
                              :id="club['short_name']"
                              :value="club['short_name']"
                              :disabled="club.mandatory"
                              v-model="selectedClubs"/>
                    <label :for="club['short_name']">{{ club.name }} - €{{ parseFloat(club.fee).toFixed(2) }}</label>
                  </div>
                </span>
            <small v-if="v$.selectedClubs.$error" class="p-error">
              Please select at least one club.
            </small>
          </Fieldset>
        </div>
        <div class="p-md-6">
          <Fieldset legend="Licences">
            <span v-for="licence in licences" :key="licence.id">
              <div class="p-field-checkbox">
                <Checkbox name="club"
                          :id="licence.id"
                          :value="licence.id"
                          v-model="selectedLicences"/>
                <label :for="licence.id">{{ licence.name }}</label>
              </div>
            </span>
          </Fieldset>
        </div>
        <div class="p-md-6">
          <Fieldset legend="Affiliated Groups">
            <span v-for="group in affiliatedGroups" :key="group['short_name']">
              <div class="p-field-checkbox">
                <Checkbox name="affiliatedGroup"
                          :id="group['short_name']"
                          :value="group['short_name']"
                          v-model="selectedAffiliatedGroups"/>
                <label :for="group['short_name']">{{ group.name }}</label>
              </div>
            </span>
          </Fieldset>
        </div>
        <div class="p-md-6">
          <Fieldset legend="Insurance">
            <div class="p-field-checkbox">
              <Checkbox name="thirdPartyInsurance"
                        id="thirdPartyInsurance"
                        :binary="true"
                        v-model="thirdPartyInsurance"/>
              <label for="thirdPartyInsurance">Third Party Liability - €13.00</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox name="personalAccidentInsurance"
                        id="personalAccidentInsurance"
                        :binary="true"
                        :disabled="insuredElsewhere"
                        v-model="personalAccidentInsurance"/>
              <label for="personalAccidentInsurance">Personal Accident - €5.00</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox name="insuredElsewhere"
                        id="insuredElsewhere"
                        :binary="true"
                        v-model="insuredElsewhere"/>
              <label for="insuredElsewhere">Insured Elsewhere</label>
              <InputText id="insuredElsewhereName"
                         :disabled="!insuredElsewhere"
                         type="text"
                         class="p-ml-2"
                         v-model.trim="insuredElsewhereName"/>
            </div>
            <small v-if="insuranceRequiredButNotSelected" class="p-error">
              Third party insurance is required for target shooting licence holders.
            </small>
            <small v-if="insuredElsewhere && insuredElsewhereName === ''" class="p-error">
              Please specify the name of the insurance agency.
            </small>
          </Fieldset>
        </div>
        <div class="p-md-12" align="right">
          <span style="font-weight: bold">Total Cost:</span> €{{ totalCost.toFixed(2)}} <span v-if="this.discount > 0">(including €{{ this.discount.toFixed(2) }} discount)</span><br/><br/>
          <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel"/>
          <Button label="Submit" @click="onSubmit"/>
        </div>
      </div>
    </Panel>
  </div>
  <ConfirmDialog/>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {email, helpers, minLength, numeric, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    const today = new Date();
    return {
      enrollmentFee: 15.00,
      yearRange: `${today.getFullYear() - 100}:${today.getFullYear()}`,
      availableGenders: [
        {name: 'Male', code: 'M'},
        {name: 'Female', code: 'F'},
        {name: 'Unspecified', code: 'X'}
      ],
      countries: [],

      idCardNumber: null,
      title: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      placeOfBirth: null,
      gender: null,
      streetAddress: null,
      town: null,
      postCode: null,
      mobileNumber: null,
      mobileNumberCountryCode: '+356',
      emailAddress: null,
      homePhone: null,
      homePhoneCountryCode: '+356',
      workPhone: null,
      workPhoneCountryCode: '+356',
      occupation: null,
      employer: null,
      photo: null,

      clubs: null,
      selectedClubs: [],

      affiliatedGroups: null,
      selectedAffiliatedGroups: [],

      insuredElsewhere: false,
      insuredElsewhereName: '',
      thirdPartyInsurance: true,
      personalAccidentInsurance: false,

      licences: [
        {id: "collector_a", name: "Collector Licence A"},
        {id: "collector_a_special", name: "Collector Licence A (Special)"},
        {id: "target_a", name: "Target Shooter Licence A"},
        {id: "target_a_special", name: "Target Shooter Licence A (Special)"},
        {id: "target_a_cb", name: "Target Shooter Licence A (Crossbow)"},
        {id: "rco", name: "Range Conducting Officer"},
        {id: "target_b_ts", name: "Target Shooter Licence B (Tactical Shotguns)"},
        {id: "target_b_ag", name: "Target Shooter Licence B (Air Guns)"},
        {id: "target_b_cp", name: "Target Shooter Licence B (Clay Pigeon)"},
        {id: "target_b_ml", name: "Target Shooter Licence B (Muzzle Loaders)"},
      ],
      selectedLicences: []
    }
  },
  computed: {
    insuranceRequiredButNotSelected() {
      const hasTargetShooterLicence = this.selectedLicences.filter(l => l.startsWith("target") || l.startsWith("rco"));
      return (hasTargetShooterLicence.length > 0) && !(this.thirdPartyInsurance || this.insuredElsewhere);
    },
    totalCost() {
      if (this.clubs === null) {
        return 0;
      }

      let cost = 0;
      this.clubs.forEach(club => cost += this.selectedClubs.includes(club.short_name) ? parseFloat(club.fee) : 0);
      if (this.thirdPartyInsurance) {
        cost += 13;
      }
      if (this.personalAccidentInsurance) {
        cost += 5;
      }

      return (cost - this.discount) + this.enrollmentFee;
    },
    discount() {
      if (this.allClubsSelected()) {
        return 10;
      }
      return 0;
    }
  },
  watch: {
    selectedLicences: {
      handler: function (newValue, oldValue) {
        const licenceAdded = newValue.filter(x => !oldValue.includes(x));
        if (licenceAdded.length === 0) {
          return;
        }

        let index = -1;
        switch (licenceAdded[0]) {
          case "collector_a":
            index = this.selectedLicences.indexOf("collector_a_special");
            break;
          case "collector_a_special":
            index = this.selectedLicences.indexOf("collector_a");
            break
          case "target_a":
            index = this.selectedLicences.indexOf("target_a_special");
            break;
          case "target_a_special":
            index = this.selectedLicences.indexOf("target_a");
            break;
        }
        if (index > -1) {
          this.selectedLicences.splice(index, 1);
        }
      },
      deep: true
    },
    insuredElsewhere: function (selected) {
      if (selected) {
        this.thirdPartyInsurance = false;
        this.personalAccidentInsurance = false;
      }
    },
    thirdPartyInsurance: function (selected) {
      if (selected) {
        this.insuredElsewhere = false;
      } else {
        this.personalAccidentInsurance = false;
      }
    }
  },
  mounted() {
    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    this.$store.dispatch('data/fetchClubs').then(data => {
      this.clubs = data.filter(c => {
        return c.enabled;
      });
      this.clubs.forEach(c => {
        if (c.mandatory) {
          this.selectedClubs.push(c['short_name']);
        }
      });
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });

    this.$store.dispatch('data/fetchAffiliatedGroups').then(data => {
      this.affiliatedGroups = data.filter(c => {
        return c.enabled;
      });
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });
  },
  validations() {
    const idCardNumber = (value) => /^[0-9]{3,7}[AaBbGgHhLlMmPpZz]/.test(value);
    const date = (value) => value instanceof Date || /\d{2}\/\d{2}\/\d{4}/.test(value);

    const validMobileNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    const validHomeNumber = (value) => {
      if (!helpers.req(value) && (value == null || value.length === 0)) return true;
      if (this.homePhoneCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    const validWorkNumber = (value) => {
      if (!helpers.req(value) && (value == null || value.length === 0)) return true;
      if (this.workPhoneCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    return {
      idCardNumber: {
        required,
        idCardNumber,
        $autoDirty: true,
      },
      title: {
        required,
        $autoDirty: true,
      },
      firstName: {
        required,
        $autoDirty: true,
      },
      lastName: {
        required,
        $autoDirty: true,
      },
      dateOfBirth: {
        required,
        date,
        $autoDirty: true,
      },
      placeOfBirth: {
        required,
        $autoDirty: true,
      },
      gender: {
        required,
        $autoDirty: true,
      },
      streetAddress: {
        required,
        $autoDirty: true,
      },
      town: {
        required,
        $autoDirty: true,
      },
      postCode: {
        required,
        $autoDirty: true,
      },
      mobileNumber: {
        required,
        numeric,
        validMobileNumber,
        $autoDirty: true,
      },
      emailAddress: {
        email,
        $autoDirty: true,
      },
      homePhone: {
        numeric,
        validHomeNumber,
        $autoDirty: true,
      },
      workPhone: {
        numeric,
        validWorkNumber,
        $autoDirty: true,
      },
      photo: {
        required,
      },
      selectedClubs: {
        required,
        minLength: minLength(2),
        $autoDirty: true,
      }
    };
  },
  methods: {
    allClubsSelected() {
      if (this.clubs === null) {
        return false;
      }
      return this.clubs.reduce((prev, curr) => prev && this.selectedClubs.includes(curr.short_name), true);
    },
    padIdCardNumber() {
      this.v$.idCardNumber.$touch();
      if (this.v$.idCardNumber.$error) {
        return;
      }
      this.idCardNumber = this.idCardNumber.padStart(8, '0');
    },
    sendCompleteIfSet(prefix, number) {
      if ((number === null) || (number === '')) {
        return null;
      }
      return `${prefix} ${number}`;
    },
    onCancel() {
      this.$confirm.require({
        message: 'Are you sure you want to cancel?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$router.push({name: 'adminListMembers'});
        },
        reject: () => {
          // do nothing
        }
      });
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error || this.insuranceRequiredButNotSelected || (this.insuredElsewhere && this.insuredElsewhereName === '')) {
        return;
      }

      const request = {
        personalDetails: {
          idCardNumber: this.idCardNumber,
          title: this.title,
          firstName: this.firstName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth,
          placeOfBirth: this.placeOfBirth,
          gender: this.gender,
          streetAddress: this.streetAddress,
          town: this.town,
          postCode: this.postCode,
          mobileNumber: this.sendCompleteIfSet(this.mobileNumberCountryCode, this.mobileNumber),
          emailAddress: this.emailAddress,
          homePhone: this.sendCompleteIfSet(this.homePhoneCountryCode, this.homePhone),
          workPhone: this.sendCompleteIfSet(this.workPhoneCountryCode, this.workPhone),
          occupation: this.occupation,
          employer: this.employer,
          photo: this.photo,
        },
        clubs: this.selectedClubs,
        affiliatedGroups: this.selectedAffiliatedGroups,
        licences: this.selectedLicences,
        insurance: {
          thirdParty: this.thirdPartyInsurance,
          personalAccident: this.personalAccidentInsurance,
          insuredElsewhere: {
            hasInsuranceElsewhere: this.insuredElsewhere,
            insuranceElsewhereAgencyName: this.insuredElsewhereName,
          }
        }
      }

      this.$store.dispatch('admin/addNewMember', request).then(_resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Member added',
          detail: 'New member was added successfully.',
          life: 3000
        });
        setTimeout(() => {
          this.$router.push({name: 'adminListMembers'});
        }, 1500);
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  }
}
</script>
