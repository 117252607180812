<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Active Member Email List">
      <DataView layout="list" :value="emails">
        <template #list="slotProps">
          <div class="p-pt-2 p-pb-2">{{slotProps.data.email}} </div>
        </template>
      </DataView>
    </Panel>
  </div>
  <Toast/>
</template>

<script>
export default {
  data() {
    return {
      emails: []
    }
  },
  mounted() {
    this.fetchEmailList();
  },
  methods: {
    fetchEmailList() {
      this.$store.dispatch('admin/fetchActiveMemberEmailList').then(data => {
        this.emails = data.map(email => ({"email": email}));
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
  }
}
</script>
