<template>
  <div class="wrapper-center-screen">
    <div class="p-lg-4 p-md-6">
      <Panel header="Verification">
        <p class="p-text-justify">You should receive an SMS in the next few minutes with a 7-digit verification code.
          Please enter this code into the field below in order to continue.</p>
        <form @submit.prevent="onSubmit">
          <div class="p-fluid">
            <div class="p-field">
              <label for="verification-code" class="p-sr-only">Verification Code</label>
              <InputText id="verification-code"
                         type="number"
                         placeholder="Verification Code"
                         v-model.trim="verificationCode"
                         :class="{'p-invalid':v$.verificationCode.$error}"/>
              <small v-if="v$.verificationCode.$error" class="p-error">
                Verification code is required and must be exactly 7 digits.
              </small>
            </div>
          </div>
          <div align="right">
            <Button type="submit" label="Submit"/>
          </div>
        </form>
      </Panel>
    </div>
    <Toast/>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {maxLength, minLength, numeric, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      verificationCode: '',
    };
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      this.$store.dispatch('members/verify', this.verificationCode).then(() => {
        this.$router.push({name: 'memberMain'});
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  },
  validations() {
    return {
      verificationCode: {
        numeric,
        required,
        minLength: minLength(7),
        maxLength: maxLength(7),
        $autoDirty: true
      }
    }
  }
}
</script>
