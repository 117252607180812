<template>
  <div class="wrapper-center-screen">
    <div class="p-lg-4 p-md-6">
      <Panel header="Login">
        <p class="p-text-justify">Please enter your username and password to receive a 7-digit SMS code in order to
          confirm your identity.</p>
        <form @submit.prevent="onSubmit">
          <div class="p-fluid">
            <div class="p-field">
              <label for="username" class="p-sr-only">Username</label>
              <InputText id="username"
                         type="text"
                         placeholder="Username"
                         v-model.trim="username"
                         :class="{'p-invalid':v$.username.$error}"/>
              <small v-if="v$.username.$error" class="p-error">
                Username is required.
              </small>
            </div>
            <div class="p-field">
              <label for="password" class="p-sr-only">Password</label>
              <InputText id="password"
                         type="password"
                         placeholder="Password"
                         v-model.trim="password"
                         autocomplete="on"
                         :class="{'p-invalid':v$.password.$error}"/>
              <small v-if="v$.password.$error" class="p-error">
                Password is required.
              </small>
            </div>
          </div>
          <div align="right">
            <Button type="submit" label="Submit"/>
          </div>
        </form>
      </Panel>
    </div>
  </div>
  <Toast/>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const request = {
        username: this.username,
        password: this.password,
      }

      this.$store.dispatch('admin/login', request).then(() => {
        this.$router.replace({name: 'adminVerify'});
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  },
  validations() {
    return {
      username: {
        required,
        $autoDirty: true
      },
      password: {
        required,
        $autoDirty: true
      }
    }
  }
}
</script>
