<template>
  <router-view></router-view>
</template>

<script>
</script>

<style>
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-center-screen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.country-item-value {
img.flag {
  width: 18px;
  margin-right: 0.5rem;
}
}
</style>
