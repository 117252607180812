import axios from 'axios';

export default {
    login(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/members/login',
                method: 'POST',
                data: {
                    'mobile_number': req.mobileNumber,
                    'membership_number': req.membershipNumber,
                }
            }).then(resp => {
                context.commit('setMembershipNumber', req.membershipNumber);
                context.commit('setMobileNumber', req.mobileNumber);
                context.commit('setLoginTime', Date.now());
                resolve(resp);
            }).catch(error => {
                context.commit('resetStore');
                switch (error.response.status) {
                    case 400:
                        reject('Could not log in as the request was malformed.');
                        break;
                    case 403:
                        reject('Member with given combination of mobile number and membership number not found.');
                        break;
                    default:
                        reject('Could not log in due to a technical error.');
                }
            });
        });
    },
    verify(context, auth_token) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/v1/members/verify',
                method: 'POST',
                data: {
                    'membership_number': context.getters.membershipNumber,
                    'auth_token': auth_token,
                },
            }).then(resp => {
                context.commit('setToken', resp.data.token);
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not verify token as the request was malformed.');
                        break;
                    case 403:
                        reject('Token not found or expired.');
                        break;
                    default:
                        reject('Could not verify token due to a technical error.');
                }
            });
        });
    },
    getMembershipDetails(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/membership-details`,
                method: 'GET',
                headers: {'Authorization': `Bearer ${context.getters.token}`}
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not get the membership details as the request was malformed.');
                        break;
                    case 403:
                        reject('Session has expired. Please refresh the path to log in again.');
                        break;
                    default:
                        reject('Could not get the membership details due to a technical error.');
                }
            })
        });
    },
    fetchMemberDetails(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/`,
                method: 'GET',
                headers: {'Authorization': `Bearer ${context.getters.token}`}
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 403:
                        context.commit('resetStore');
                        reject('Session has expired. Please refresh the path to log in again.');
                        break;
                    default:
                        reject('Could not fetch details due to a technical error.');
                }
            });
        });
    },
    updateMemberDetails(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/`,
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${context.getters.token}`},
                data: {
                    'id_number': req.idCardNumber,
                    'title': req.title,
                    'first_name': req.firstName,
                    'last_name': req.lastName,
                    'date_of_birth': req.dateOfBirth.toISOString(),
                    'place_of_birth': req.placeOfBirth,
                    'address': {
                        'street': req.streetAddress,
                        'city': req.town,
                        'post_code': req.postCode,
                    },
                    'mobile_number': req.mobileNumber,
                    'email': req.emailAddress,
                    'home_phone': req.homePhone,
                    'office_phone': req.workPhone,
                    'occupation': req.occupation,
                    'employer': req.employer,
                    'photo': req.photo
                },
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update member details as request was malformed.');
                        break;
                    case 403:
                        context.commit('resetStore');
                        reject('Session has expired. Please refresh the path to log in again.');
                        break;
                    default:
                        reject('Could not update member details due to a technical error.');
                }
            })
        });
    },
    fetchLatestLicences(context) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/licences/latest`,
                method: 'GET',
                headers: {'Authorization': `Bearer ${context.getters.token}`}
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not get latest member licences as request was malformed.');
                        break;
                    case 403:
                        reject('Could not get latest member licences. Please refresh the path to log in again.');
                        break;
                    case 404:
                        reject('Latest member licences were not found. Please refresh the path to log in again.');
                        break;
                    default:
                        reject('Could not update member details due to a technical error.');
                }
            });
        });
    },
    updateLatestLicences(context, req) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/licences/latest`,
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${context.getters.token}`},
                data: {
                    'licences': req
                },
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not update member licences as request was malformed.');
                        break;
                    case 403:
                        context.commit('resetStore');
                        reject('Session has expired. Please refresh the path to log in again.');
                        break;
                    default:
                        reject('Could not update member details due to a technical error.');
                }
            })
        });
    },
    prepareForCheckout(context) {
        return new Promise((resolve, reject) => {
            const info = context.getters.renewalInformation;
            if (info === null) {
                reject('Cannot prepare for checkout: no renewal information');
            }
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/prepare-checkout`,
                method: 'POST',
                headers: {'Authorization': `Bearer ${context.getters.token}`},
                data: {
                    'year': info['renewalYear'],
                    'clubs': info['clubs'],
                    'affiliated_groups': info['affiliatedGroups'],
                    'licences': info['licences'],
                    'insurance': {
                        'third_party': info['insurance']['thirdParty'],
                        'personal_accident': info['insurance']['personalAccident'],
                        'insured_elsewhere': {
                            'has_insurance_elsewhere': info['insurance']['insuredElsewhere']['hasInsuranceElsewhere'],
                            'other_insurance_agency_name': info['insurance']['insuredElsewhere']['otherInsuranceAgencyName']
                        },
                    },
                },
            }).then(resp => {
                resolve(resp.data);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not prepare for checkout as the request was malformed.');
                        break;
                    case 403:
                        context.commit('resetStore');
                        reject('Session has expired. Please refresh the path to log in again.');
                        break;
                    default:
                        reject('Could not prepare for checkout due to a technical error.');
                }
            })
        });
    },
    checkout(context, nonce) {
        return new Promise((resolve, reject) => {
            const info = context.getters.renewalInformation;
            if (info === null) {
                reject('Cannot checkout: no renewal information');
            }
            axios({
                url: `/v1/members/member/${context.getters.membershipNumber}/checkout`,
                method: 'POST',
                headers: {'Authorization': `Bearer ${context.getters.token}`},
                data: {
                    'renewal': {
                        'clubs': info['clubs'],
                        'affiliated_groups': info['affiliatedGroups'],
                        'licences': info['licences'],
                        'insurance': {
                            'third_party': info['insurance']['thirdParty'],
                            'personal_accident': info['insurance']['personalAccident'],
                            'insured_elsewhere': {
                                'has_insurance_elsewhere': info['insurance']['insuredElsewhere']['hasInsuranceElsewhere'],
                                'other_insurance_agency_name': info['insurance']['insuredElsewhere']['otherInsuranceAgencyName']
                            },
                        },
                    },
                    'payment_nonce': nonce,
                },
            }).then(resp => {
                resolve(resp);
            }).catch(error => {
                switch (error.response.status) {
                    case 400:
                        reject('Could not checkout as the request was malformed.');
                        break;
                    case 403:
                        context.commit('resetStore');
                        reject('Session has expired. Please refresh the path to log in again.');
                        break;
                    case 424:
                        reject(error.response.data['reason']);
                        break;
                    default:
                        reject('Could not checkout due to a technical error.');
                }
            })
        });
    }
}
