<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Card>
      <template #content>
        <DataTable :value="admins">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <h5 class="p-m-0">Administrators</h5>
              <span class="p-input-icon-left">
                <Button label="Add"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="onAddAdmin()"></Button>
              </span>
            </div>
          </template>
          <Column field="firstName" header="Name"></Column>
          <Column field="lastName" header="Surname"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="mobile" header="Mobile #"></Column>
          <Column field="group" header="Group" :sortable="true"></Column>
          <Column field="enabled" headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: right; overflow: visible">
            <template #body="slotProps">
              <Button :label="slotProps.data.enabled ? 'Disable' : 'Enable'"
                      :class="slotProps.data.enabled ? 'p-button-danger' : 'p-button-success'"
                      @click="setStatus(slotProps.data.username, !slotProps.data.enabled)"></Button>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <Toast/>
</template>

<script>
export default {
  data() {
    return {
      admins: []
    }
  },
  mounted() {
    this.fetchAdmins();
  },
  methods: {
    fetchAdmins() {
      this.$store.dispatch('admin/fetchAdmins').then(data => {
        this.admins = data;
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    },
    setStatus(username, enabled) {
      const request = {
        username: username,
        status: enabled,
      }

      this.$store.dispatch('admin/setAdminAccountStatus', request).then(_data => {
        this.fetchAdmins();
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      })
    },
    onAddAdmin() {
      this.$router.push({name: 'adminAddNewAdmin'});
    }
  }
}
</script>
